<template>
  <div>
    <div>
      <div v-if="showTitle">
        <h5 class="font-weight-light mb-4 text-center pt-30 mx-2">For a more accurate estimate, please check all that apply:</h5>
        <hr class="height-1 color-green">
      </div>
      <div :class="containerClass">
        <div :class="formClass">
          <div v-for="(label, key) in questions" class="breakdown-item text-left my-2" :key="key">
            <template v-if="key == 'wantsPetsOffer'">
                <label @click="$emit('show-pet-modal')" class="checkbox-set text-small">&nbsp;{{ label }} &nbsp;&nbsp;</label>
            </template>
            <template v-else>
              <label class="checkbox-set breakdown-item text-small">
                  &nbsp;{{ label }} &nbsp;&nbsp;
                  <input :checked="rawValues[key]" @input="setValue(key, $event)" type="checkbox">
                  <span class="checkmark"></span>
              </label>
            </template>
          </div>
        </div>
      </div>
      <div v-if="showPortableInfo" class="px-4 pb-4">
        <div class="breakdown-info text-center">
          <b-button variant="link" class="font-weight-light pt-0 white-text" v-b-toggle.collapse-info> <i class="fal fa-info-circle"></i> More info.</b-button>
        </div>
        <b-collapse id="collapse-info">
          <small>
          Portable equipment may be required for areas above the 2nd floor or if guaranteed adjacent parking is not available. Use of portable equipment may result in a higher required portable job minimum, a portable surcharge or a price increase, with necessary price adjustments made at time of service. Contact us for more information.
          </small>
        </b-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
export default {
  props: {
    formCentered: {
      type: Boolean,
      default: false
    },
    formClass: {
      type: String,
      default: 'form-check'
    },
    questions: {
      type: Object,
      default() {
        return {
          parkingNearby: 'I do not have parking nearby',
          guaranteedParking: 'I have guaranteed parking',
          higherFloor: 'Area is on 3rd floor or higher'
        }
      }
    },
    values: {
      type: Object,
      default() {
        return {}
      }
    },
    showPortableInfo: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rawValues: this.values
    }
  },
  computed: {
    containerClass() {
      if (this.formCentered) {
        return 'd-table mx-auto'
      }
      return 'px-4 pt-md-2'
    },
  },
  methods: {
    setValue(key, event) {
      const value = event.target.checked
      Vue.set(this.rawValues, key, value)
      this.$emit('update', { attr: key, value: value })
    }
  },
  watch: {
    values(newValue) {
      console.log('values changed', newValue)
    }
  }

}
</script>
