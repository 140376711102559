<template>
  <div>
    <topheader></topheader>
    <div class="page-wrapper">
      <no-card-warning :no-card="clientHasNoCard" :show-link="false"></no-card-warning>
      <payment-settings :client-id="clientId" @start-indicator="startIndicator" @stop-indicator="stopIndicator"/>
      <div class="row px-0 mx-0 mt-4">
        <div class="col">
          <div class="mt-2 mb-2">
            <h2 class="font-weight-light text-center">Billing History</h2>
          </div>
        </div>
      </div>
      <div class="no-margin">
        <div class="col px-0">
          <div class="box-wrapper-gray py-5 px-5">
            <div v-if="!loading" class="white-bg border-radius-5">
              <div class="px-4 pt-3">
                <payment-row v-for="(payment, idx) in payments" :payment="payment" :key="idx"></payment-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import api from '@/clients/api'
import PaymentRow from '@/shared/payment_row.vue'
import PaymentSettings from '@/shared/client_payment_settings.vue'
import no_card_warning_mixin from '@/shared/no_card_warning_mixin'
import auth_mixin from '@/shared/auth_mixin'
Vue.mixin(auth_mixin)
export default {
  mixins: [ no_card_warning_mixin ],
  components: { PaymentRow, PaymentSettings },
  data() {
    return {
      payments: [],
    }
  },
  mounted() {
    if ( (this.phones == null) || (this.phones.length == 0) || (this.has_invalid_phone) ) {
      this.$store.dispatch('client/toAddPhone')
      this.$flasher.error('You need to add your phone before booking')
      return
    }
    if (this.$attrs.startEdit != null) {
      this.startInEditMode = this.$attrs.startEdit
    }
    document.getElementsByTagName('body')[0].className = 'white-theme'
    this.loadRecentPayments()
    if (this.hasPastDueBalance) {
      this.$flasher.error('You have past due balance')
    }
  },
  computed: {
    clientId() {
      return this.$store.getters['client/client_id']
    },
    ...mapGetters(['loading']),
    ...mapGetters({
      client_id: 'client/client_id',
      phones: 'client/phones',
      has_invalid_phone: 'client/has_invalid_phone',
      hasPastDueBalance: 'client/hasPastDueBalance'
    })
  },
  methods: {
    startIndicator() {
      this.$store.commit('START_INDICATOR')
    },
    stopIndicator() {
      this.$store.commit('STOP_INDICATOR')
    },
    loadRecentPayments() {
      this.startIndicator()
      api.getRecentPayments().then((response) => {
        this.stopIndicator()
        this.payments = response.data.payments
      })
    }
  }
}

</script>
