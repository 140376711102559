<template>
  <div class="row">
    <div class="col-md-12">
      <address-fallback-field v-model="addressFallback"></address-fallback-field>
    </div>
    <div class="col-md-6">
      <b-form-group label="Apartment, unit, suite, or floor #" label-for="address2">
        <b-form-input v-model="address2Fallback" id="address2" />
      </b-form-group>
    </div>
    <div class="col-md-6">
      <city-fallback-field v-model="cityFallback"></city-fallback-field>
    </div>
    <div class="col-md-6">
      <state-fallback-field v-model="stateFallback"></state-fallback-field>
    </div>
    <div class="col-md-6">
      <zip-code-fallback-field v-model="zip_codeFallback"></zip-code-fallback-field>
    </div>
  </div>
</template>

<script>
import AddressFallbackField from '@/shared/fields/address.vue'
import CityFallbackField from '@/shared/fields/city.vue'
import StateFallbackField from '@/shared/fields/state.vue'
import ZipCodeFallbackField from '@/shared/fields/zip.vue'

export default {
  components: {
    AddressFallbackField,
    CityFallbackField,
    StateFallbackField,
    ZipCodeFallbackField
  },
  props: {
    varPrefix: {
      type: String
    },
    address: {
      type: Object
    },
    vars: {
      type: Object,
      default() {
        return { address: 'address', address2: 'address2', city: 'city', state: 'state', postal_code: 'zip_code', formatted_address: 'fullAddress' }
      }
    }
  },
  computed: {
    addressFallback: {
      set(v) {
        this.setParentVarAndEmitChange('address', v)
      },
      get() {
        if (this.address) {
          return this.address.address
        }
      }
    },
    address2Fallback: {
      set(v) {
        this.setParentVarAndEmitChange('address2', v)
      },
      get() {
        if (this.address) {
          return this.address.address2
        }
      }
    },
    cityFallback: {
      set(v) {
        this.setParentVarAndEmitChange('city', v)
      },
      get() {
        if (this.address) {
          return this.address.city
        }
      }
    },
    stateFallback: {
      set(v) {
        this.setParentVarAndEmitChange('state', v)
      },
      get() {
        if (this.address) {
          return this.address.state
        }
      }
    },
    zip_codeFallback: {
      set(v) {
        this.setParentVarAndEmitChange('postal_code', v)
      },
      get() {
        if (this.address) {
          return this.address.zip_code
        }
      }
    },
  },
  methods: {
    setParentVarAndEmitChange(varname, value) {
      const parentVarName = this.varPrefix ? (this.varPrefix + '.' + this.vars[varname]) : this.vars[varname]
      this.$emit('update', { field: parentVarName, value: value })
    }
  },
}
</script>
