import Vue from 'vue'
import VueRouter from 'vue-router/dist/vue-router.esm'
import VueGtm from '@gtm-support/vue2-gtm';

import SignUp from './views/sign_up.vue'
////import SignIn from './views/sign_in'
import NewProperty from './views/new_property.vue'
import FixProperty from './views/fix_property.vue'
import AddPhone from './views/add_phone.vue'
import Plan from './views/plan_selection.vue'
import Hwe from './views/hwe.vue'
import Details from './views/confirm_details.vue'
import ServiceSelection from './views/service_selection.vue'
import Extras from './views/add_extras.vue'
import Confirmation from './views/confirmation.vue'
import PropertySelection from './views/property_selection.vue'
import Properties from './views/properties.vue'
import ManageProperty from './views/manage_property.vue'
import Profile from './views/profile.vue'
import Calendar from './views/calendar.vue'
import Invoices from './views/invoices.vue'
import Billing from './views/billing.vue'
import RateCleaning from './views/rate_cleaning.vue'
import StartDispute from './views/start_dispute.vue'
import Referral from './views/referral.vue'
import store from './store'


//const DEBUG_ROUTER = true

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'pick-property',
//      component: () => import('./views/property_selection'),
      component: PropertySelection,
      beforeEnter: requireAuth
    },
    {
      path: '/book_reservation',
      name: 'booking',
      component: SignUp
    },
    {
      path: '/new-booking',
      beforeEnter(to, from, next) {
        if (DEBUG_ROUTER) {
          console.log("router /new-booking")
        }
        store.dispatch('resetBooking').then(() => {
          if (store.getters['client/isAuthenticated'] === true) {
            next({ name: 'pick-property' })
          } else {
            next('/book_reservation')
          }
        })
      }
    },
    {
      path: '/logout',
      beforeEnter(to, from, next) {
        store.dispatch('client/signOut').then(() => {
          next('/book_reservation');
        })
      }
    },
    {
      path: '/locations/new',
      name: 'new-property',
      component: NewProperty,
      beforeEnter: requireAuth
    },
    {
      path: '/locations/:id/edit',
      name: 'fix-property',
      component: FixProperty,
      beforeEnter: requireAuth
    },
    {
      path: '/add_phone',
      name: 'add-phone',
      component: AddPhone,
      beforeEnter: requireAuth
    },
    {
      path: '/book_reservation/plan',
      name: 'plan',
      component: Plan,
      beforeEnter: requireAuth
    },
    {
      path: '/book_reservation/service_selection',
      name: 'service-selection',
      component: ServiceSelection,
      beforeEnter: requireAuth
    },
    {
      path: '/book_reservation/hwe',
      name: 'carpet-cleaning',
      component: Hwe,
      beforeEnter: requireAuth
    },
    {
      path: '/book_reservation/extras',
      name: 'extras',
      component: Extras,
      beforeEnter: requireAuth
    },
    {
      path: '/book_reservation/details',
      name: 'details',
      component: Details,
      beforeEnter: requireAuth
    },
    {
      path: '/book_reservation/confirmation',
      name: 'confirmation',
      component: Confirmation,
      beforeEnter: requireAuth
    },
    {
      path: '/properties/:id',
      component: ManageProperty,
      beforeEnter: requireAuth
    },
    {
      path: '/properties/:id/reservations',
      component: ManageProperty,
      props: { initTab: 'visits' },
      beforeEnter: requireAuth
    },
    {
      path: '/properties/:id/reservations/:visit_id',
      component: ManageProperty,
      props: { initTab: 'visits' },
      beforeEnter: requireAuth
    },
    {
      path: '/properties/:id/reservations/:visit_id/updates',
      component: ManageProperty,
      props: { initTab: 'visits', visitTab: 'updates' },
      beforeEnter: requireAuth
    },
    {
      path: '/properties/:id/reservations/:visit_id/feedback',
      component: ManageProperty,
      props: { initTab: 'visits', visitTab: 'feedback' },
      beforeEnter: requireAuth
    },
    {
      path: '/properties/:id/reservations/:visit_id/meetup',
      component: ManageProperty,
      props: { initTab: 'visits', visitTab: 'meetup' },
      beforeEnter: requireAuth
    },
    {
      path: '/properties',
      component: Properties,
      beforeEnter: requireAuth
    },
    {
      path: '/rate',
      component: RateCleaning,
      beforeEnter: requireAuth
    },
    {
      path: '/invoices',
      component: Invoices,
      beforeEnter: requireAuth
    },
    {
      path: '/profile',
      component: Profile,
      beforeEnter: requireAuth
    },
    {
      path: '/profile/edit',
      component: Profile,
      props: { startEdit: true },
      beforeEnter: requireAuth
    },
    {
      path: '/billing',
      name: 'billing',
      component: Billing,
      beforeEnter: requireAuth
    },
    {
      path: '/calendar',
      component: Calendar,
      beforeEnter: requireAuth
    },
    {
      path: '/start_dispute/:job_id',
      component: StartDispute,
      beforeEnter: requireAuth
    },
    {
      path: '/referral',
      component: Referral,
      beforeEnter: requireAuth
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

Vue.use(VueGtm, {
  id: 'GTM-MCVBGWP', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
  compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
  enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
});

router.beforeEach((to, from, next) => {
  let resume = ((to.path == '/') && (from.path == '/')) //|| (to.path == '/book_reservation')
    if (DEBUG_ROUTER) {
      console.log('clients/router beforeEach', to.path, from.path, resume)
    }
    store.commit('HIDE_SIDEBAR')
    if (resume) {
      store.dispatch('resumeBooking').then(() => {
        next()
        return
      }, (errors) => {
        if (errors) {
          if (DEBUG_ROUTER) {
            console.error("clients/router beforeEach error at resumeBooking", errors)
          }
        } else {
          if (DEBUG_ROUTER) {
            console.error("clients/router beforeEach error at resumeBooking without error message -> booking can't be resumed")
          }
        }
      })
    }
    next()
})

function requireAuth(to, from, next) {
  Window.user = null
  Window.user_id = null
  store.dispatch('client/login').then(() => {
    if (DEBUG_ROUTER) {
      console.log('clients/router requireAuth login SUCCESS', from.path, to.path)
    }
    Window.user = 'Client'
    Window.user_id = store.getters['client/client_id']
    next()
  }, () => {
    if (to.path == "/referral") {
        window.location.href = '/login';
        return
    }
    if (DEBUG_ROUTER) {
      console.log('clients/router requireAuth login FAILED', from.path, '-> /book_reservation')
    }
    next({ path: '/book_reservation' })
  })
}

export default router
