import Vue from 'vue'
import Vuex from 'vuex/dist/vuex.esm'
import api from '@/clients/api'
import router from '@/clients/router'
import { dateToAppFormat } from '@/utils'

import * as reservation from './modules/reservation'
import * as hwe from './modules/hwe'
import * as client from './modules/client'
import * as property from './modules/property'

Vue.use(Vuex)

export const namespaced = true
const debug = typeof DEBUG_CLIENT_STORE !== 'undefined' ? DEBUG_CLIENT_STORE : false;

const loadFromStore = function(key) {
  const json = localStorage.getItem(key)
  if (debug) {
    console.log("CLIENT STORE loadFromStore", key, json)
  }
  if (json) {
    return JSON.parse(json)
  }
}

const saveToStore = function(key, value) {
  if (value == null) {
    if (debug) {
      console.log("CLIENT STORE saveToStore REMOVE", key, value)
    }
    localStorage.removeItem(key)
  } else {
    if (debug) {
      console.log("CLIENT STORE saveToStore SET", key, value)
    }
    localStorage.setItem(key, JSON.stringify(value))
  }
}

const initialState = function() {
  return {
    loadingCounter: 0,
    indicatorMessage: 'Loading',
    doubleBookingMessage: '',
    ignoreDoubleBooking: false,
    wantsConfirmationModal: false,
    showAdvancedNotesModal: false,
    showTermsModal: false,
    showDoubleBookingModal: false,
    showServiceSelectionModal: false,
    prices: {},
    currentStep: '',
    prevStep: null,
    sidebarOpen: false,
    showMenu: true,
    bookingRequestId: null,
    reservationType: 'PropertyCleaning',
    //// Should be in the app's TZ! UTC-8
    minDate: new Date()
  }
}

const state = initialState

const getters = {
  isBooking: (state, getters) => {
    return getters.step > 1
  },
  finishedBooking: (state, getters) => {
    return !!getters.bookingRequestId
  },
  minDate: state => state.minDate,
  bookingRequestId: state => state.bookingRequestId,
  loading: (state) => {
    return state.loadingCounter > 0
  },
  currentUser: (state, getters) => {
    if (getters['client/isAuthenticated']) {
      return 'client'
    }
  },
  currentStep: state => state.currentStep,
  reservationType: state => state.reservationType,
  step: (state, getters) => {
    if (state.currentStep == '') {
      return 1
    }
    if (state.currentStep == 'service-selection') {
      return 1.5
    }
    if (getters.reservationType == 'CarpetCleaning') {
      console.error('NO STEP YET')
    } else {
      if (state.currentStep == 'plan') {
        return 2
      }
      if (state.currentStep == 'extras') {
        return 3
      }
      if (state.currentStep == 'details') {
        return 4
      }
    }
    return 0
  },
  sidebarOpen: state => state.sidebarOpen,
  showAdvancedNotesModal: state => state.showAdvancedNotesModal,
  showTermsModal: state => state.showTermsModal,
  showMenu: state => state.showMenu,
  isClient: (state, getters) => {
    return getters.currentUser == 'client'
  },
  isGlobalAdmin: (state, getters) => {
    return getters.currentUser == 'global_admin'
  },
  isServiceAreaAdmin: (state, getters) => {
    return getters.currentUser == 'service_area_admin'
  },
  indicatorMessage: state => state.indicatorMessage,
  prices: state => state.prices,
  doubleBookingMessage: state => state.doubleBookingMessage,
  showDoubleBookingModal: state => state.showDoubleBookingModal,
  showServiceSelectionModal: state => state.showServiceSelectionModal,
  ignoreDoubleBooking: state => state.ignoreDoubleBooking,
  showConfirmationModal: (state, getters) => {
    return getters.finishedBooking && state.wantsConfirmationModal
  },
  summaryInfo: (state, getters) => {
    var start
    var flexibleDescription
    if (getters['reservation/isStartPicked']) {
      start = new Date(getters['reservation/start']).toString('h:mm tt, d MMM, yyyy')
    }
    if (getters['reservation/isFlexibleBooking']) {
      const flexible = getters['reservation/flexible']
      flexibleDescription = flexible.description
    }
    var info = {
      cleaning: getters.reservationType,
      requestedCleaning: getters['reservation/requestedCleaning'],
      frequency: getters['reservation/frequency'],
      start: start,
      flexibleDescription: flexibleDescription,
      isStartPicked: getters['reservation/isStartPicked'],
      property: getters['property/details'],
      prices: getters.prices,
      promoCode: getters['reservation/promo_code'],
      chargeParkingFee: getters['property/chargeParkingFee'],
      activeCoupon: getters['reservation/active_coupon'],
    }
    if (getters.reservationType == 'PropertyCleaning') {
      info.pickedExtras = getters['reservation/pickedExtras']
      info.extrasCost = getters['property/extrasCost']
    }
    if (getters.reservationType == 'CarpetCleaning') {
      info.carpetCleaningPrice = getters['hwe/pickedTotal']
    }
    return info
  }
}

const mutations = {
  RESET(state) {
    const s = initialState()
    if (debug) {
      console.log("CLIENT STORE RESET")
    }
    Object.keys(s).forEach(key => {
      state[key] = s[key]
      localStorage.removeItem(key)
    })
  },
  SET_PRICES(state, prices) {
    state.prices = prices
    saveToStore('prices', prices)
  },
  SET_BOOKING_REQUEST_ID(state, requestId) {
    state.bookingRequestId = requestId
  },
  SET_CURRENT_STEP(state, step) {
    if (debug) {
      console.log("CLIENT STORE SET_CURRENT_STEP", step)
    }
    state.currentStep = step
    saveToStore('currentStep', step)
  },
  SET_SHOW_MENU(state, newState) {
    state.showMenu = newState
  },
  SET_PREV_STEP(state, step) {
    state.prevStep = step
    saveToStore('prevStep', step)
  },
  SET_INDICATOR_MESSAGE(state, newMessage) {
    state.indicatorMessage = newMessage
  },
  START_INDICATOR(state) {
    state.loadingCounter += 1
  },
  STOP_INDICATOR(state) {
    state.loadingCounter -= 1
  },
  WANTS_CONFIRMATION_MODAL(state) {
    state.wantsConfirmationModal = true
  },
  SHOW_ADVANCED_NOTES_MODAL(state) {
    state.showAdvancedNotesModal = true
  },
  CLOSE_NOTES_MODAL(state) {
    state.showAdvancedNotesModal = false
  },
  SHOW_TERMS_MODAL(state) {
    state.showTermsModal = true
  },
  CLOSE_TERMS_MODAL(state) {
    state.showTermsModal = false
  },
  SET_DOUBLE_BOOKING_MESSAGE(state, message) {
    state.doubleBookingMessage = message
  },
  SHOW_DOUBLE_BOOKING_MODAL(state) {
    state.showDoubleBookingModal = true
  },
  SHOW_SERVICE_SELECTION_MODAL(state) {
    state.showServiceSelectionModal = true
  },
  HIDE_SERVICE_SELECTION_MODAL(state) {
    state.showServiceSelectionModal = false
  },
  PICK_CARPET_CLEANING_SERVICE(state) {
    state.reservationType = 'CarpetCleaning'
  },
  PICK_PROPERTY_CLEANING_SERVICE(state) {
    state.reservationType = 'PropertyCleaning'
  },
  HIDE_DOUBLE_BOOKING_MODAL(state) {
    state.showDoubleBookingModal = false
  },
  SET_IGNORE_DOUBLE_BOOKING(state) {
    state.ignoreDoubleBooking = true
  },
  TOGGLE_SIDEBAR(state) {
    state.sidebarOpen = !state.sidebarOpen
  },
  SHOW_SIDEBAR(state) {
    state.sidebarOpen = true
  },
  HIDE_SIDEBAR(state) {
    state.sidebarOpen = false
  },
  SET_ADDITIONAL_INFO(state) {

  }
}

const actions = {
  book({ commit, dispatch, getters }) {
    var period
    if (getters['reservation/flexible'].preferredPeriod) {
      period = [
        dateToAppFormat(getters['reservation/flexible'].preferredPeriod[0]),
        dateToAppFormat(getters['reservation/flexible'].preferredPeriod[1])
      ]
    }
    const flexible = {
      preferred_period: period,
      preferred_contacts: getters['reservation/flexible'].preferredContacts,
      preferred_time: getters['reservation/flexible'].preferredTime,
      preferred_days: getters['reservation/flexible'].preferredDays,
      confirm: getters['reservation/flexible'].confirm
    }
    var reservation = {
      reservation_type: getters.reservationType,
      start: getters['reservation/start'],
      status: getters['reservation/status'],
      location_id: getters['property/location_id'],
      promo_code: getters['reservation/promo_code'],
      someone_home: getters['reservation/someone_home'],
      attachment: getters['reservation/attachment'],
      ignore_double_booking: getters.ignoreDoubleBooking,
      quote_only:  getters['reservation/quote_only'],
      flexible: flexible,
      send_confirmation: 1,
      schedule_attributes: getters['reservation/schedule']
    }

    if (getters.reservationType == 'PropertyCleaning') {
      reservation.requested_cleaning = getters['reservation/requestedCleaning']
      reservation.save_extras = getters['property/saveExtras'] ? true : false
      reservation.extras = JSON.stringify(getters['property/extras'])
    } else {
      reservation.hwe_services = JSON.stringify(getters['hwe/pickedServices'])
      reservation.hwe_info = JSON.stringify(getters['hwe/info'])
    }

    const client = {
      phone: getters['client/phone']
    }
    var location = {
      has_free_parking: getters['property/hasFreeParking'],
      has_pet: getters['property/hasPet'],
      wants_sitter4paws: getters['property/wantsSitter4Paws'],
    }
    var params = { reservation: reservation, client: client, booking: 1 }
    if (getters['property/isLocationIncomplete']) {
      location.address = getters['property/address']
      location.city = getters['property/city']
      location.state = getters['property/state']
      location.zip_code = getters['property/zip_code']
    }
    params.location = location
    return new Promise((resolve, reject) => {
      api
        .requestBooking(params)
        .then(response => {
          dispatch('setBookingRequestId', response.data.request_id)
          dispatch('property/setAttr', { attr: 'map_img_src', value: response.data.map_img_src })
          dispatch('reservation/setAttr', { attr: 'confirmation_details', value: response.data.confirmation_details })
          dispatch('reservation/setAttr', { attr: 'confirmation_title', value: response.data.confirmation_title })
          dispatch('reservation/setAttr', { attr: 'confirmation_text', value: response.data.confirmation_text })
          dispatch('toConfirmation')
          resolve()
        }, (error) => {
          const errors = error.response.data
          if (errors && errors.double_booking) {
            dispatch('showDoubleBookingModal', errors.double_booking)
            reject({ errors: [], error_hash: {} })
            return
          }
          reject(error)
        })
    })
  },
  startBooking({ commit }) {
    if (debug) {
      console.log("CLIENT STORE startBooking")
    }
    commit('SET_CURRENT_STEP', 'plan')
  },
  startNewBooking({ commit, dispatch }) {
    if (debug) {
      console.log("CLIENT STORE startNewBooking")
    }
    dispatch('resetBooking', { dbg: 'startNewBooking' }).then(() => {
      router.push({ name: 'booking' })
    })
  },
  showMenu({ commit }) {
    commit('SET_SHOW_MENU', true)
  },
  hideMenu({ commit }) {
    commit('SET_SHOW_MENU', false)
  },
  resetBooking({ commit, dispatch }, params) {
    if (debug) {
      console.log("CLIENT STORE resetBooking", params)
    }
    return new Promise((resolve, reject) => {
      commit('RESET')
      dispatch('reservation/reset').then(() => {
        dispatch('client/reset').then(() => {
          dispatch('property/reset').then(() => {
            resolve()
          })
        })
      })
    })
  },
  rememberValues({ commit, dispatch ,getters }) {
    saveToStore('prices', getters.prices)
    dispatch('reservation/remember')
    dispatch('property/remember')
  },
  resumeBooking({ commit, dispatch, getters }, nextForRouter) {
    return new Promise((resolve, reject) => {
      commit('SET_CURRENT_STEP', loadFromStore('currentStep'))
      if (debug) {
       console.log("CLIENT STORE resumeBooking, isBooking:",getters.isBooking, 'getters.step:',getters.step)
      }
      if (getters.isBooking) {
        commit('SET_PRICES', loadFromStore('prices'))
        dispatch('reservation/resume')
        dispatch('client/resume')
        dispatch('property/resume')
        router.push({ name: getters.currentStep })
        resolve()
      } else {
        reject()
      }
    })
  },
  toSignUp({ commit, getters }) {
    if (debug) {
      console.log("CLIENT STORE toSignUp")
    }
    commit('SET_CURRENT_STEP', '')
    router.push({ name: 'booking' })
  },
  toCarpetCleaning({ commit, getters }) {
    if (debug) {
      console.log("CLIENT STORE toCarpetCleaning")
    }
    commit('SET_CURRENT_STEP', 'carpet_cleaning')
    router.push({ name: 'carpet-cleaning' })
  },
  toPlanSelection({ commit, getters }) {
    if (debug) {
      console.log("CLIENT STORE toPlanSelection")
    }
    commit('SET_CURRENT_STEP', 'plan')
    router.push({ name: 'plan' })
  },
  toPropertySelection( { commit }) {
    router.push({ name: 'pick-property' })
  },
  toServiceSelection({ commit, getters }) {
    if (debug) {
      console.log("CLIENT STORE toServiceSelection")
    }
    if (getters['property/hweEnabled']) {
      commit('SET_CURRENT_STEP', 'service-selection')
      router.push({ name: 'service-selection' })
    } else {
      commit('SET_CURRENT_STEP', 'plan')
      router.push({ name: 'plan' })
    }
  },
  toBilling({ commit, getters }) {
    if (debug) {
      console.log("CLIENT STORE toBilling")
    }
    router.push({ name: 'billing' })
  },
  toExtras({ commit, getters }) {
    if (debug) {
      console.log("CLIENT STORE toExtras")
    }
    commit('SET_CURRENT_STEP', 'extras')
    commit('SET_PREV_STEP', 'plan')
    router.push({ name: 'extras' })
  },
  toDetails({ commit, getters }) {
    if (debug) {
      console.log("CLIENT STORE toDetails")
    }
    commit('SET_CURRENT_STEP', 'details')
    commit('SET_PREV_STEP', 'extras')
    router.push({ name: 'details' })
  },
  toConfirmation({ commit, getters }) {
    if (debug) {
      console.log("CLIENT STORE toConfirmation")
    }
    commit('SET_CURRENT_STEP', '')
    commit('SET_SHOW_MENU', true)
    router.push({ name: 'confirmation' })
  },
  setPrices({ commit }, prices) {
    commit('SET_PRICES', prices)
  },
  setBookingRequestId({ commit }, requestId) {
    commit('SET_BOOKING_REQUEST_ID', requestId)
  },
  wantsConfirmationModal({ commit }) {
    commit('WANTS_CONFIRMATION_MODAL')
  },
  toggleSidebar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  showAdvancedNotesModal({ commit, dispatch, getters }) {
    dispatch('property/ensureNote').then(() => {
      commit('SHOW_ADVANCED_NOTES_MODAL')
    })
  },
  closeNotesModal({ commit }) {
    commit('CLOSE_NOTES_MODAL')
  },
  showTermsModal({ commit }) {
    commit('SHOW_TERMS_MODAL')
  },
  closeTermsModal({ commit }) {
    commit('CLOSE_TERMS_MODAL')
  },
  showDoubleBookingModal({ commit }, message) {
    commit('SET_DOUBLE_BOOKING_MESSAGE', message)
    commit('SHOW_DOUBLE_BOOKING_MODAL')
  },
  confirmDoubleBooking({ commit, dispatch }) {
    commit('SET_IGNORE_DOUBLE_BOOKING')
  },
  closeDoubleBookingModal({ commit }) {
    commit('HIDE_DOUBLE_BOOKING_MODAL')
  },
  setIndicatorMessage({ commit }, newMessage) {
    commit('SET_INDICATOR_MESSAGE', newMessage)
  },
  resetIndicatorMessage({ commit }) {
    const s = initialState()
    commit('SET_INDICATOR_MESSAGE', s.indicatorMessage)
  },
  setAdditionalInfo({ commit }, { info, value }) {
    if (info == 'hasFreeParking') {

    }
    console.log('setAdditionalInfo', info, value)
  }
}

export default new Vuex.Store({
  modules: {
    reservation,
    client,
    property,
    hwe
  },
  actions,
  state,
  getters,
  mutations,
  strict: true
})
