<template>
  <div id="content">
    <topheader></topheader>
    <mobile-summary-widget
        :info="summaryInfo"
        :promo-code="promoCode"
        @save:promo_code="$emit('save:promo_code')"
        @remove:promo_code="$emit('remove:promo_code')"
        @update:promo_code="$emit('update:promo_code', $event)"
    />
    <div class="container-fluid px-md-5">
      <div class="row d-flex justify-content-center">
        <div class="content-wrapper booking-main-content mx-3">
          <step-indicator />
          <div class="form-wrapper">
            <div v-if="showPrevArrow"  @click="toPrevStep" class="prev-button">
              <i class="fas fa-angle-left fa-3x"></i>
            </div>
            <h2 class="form-head mb-4 pt-sm-4">Choose Cleaning Plan</h2>
            <h6 class="text-center px-sm-4">{{header}}</h6>
            <form @submit.prevent="onSubmit" class="pb-xs-5">
              <standard-cleaning-card></standard-cleaning-card>
              <upkeep-cleaning-card></upkeep-cleaning-card>
              <move-in-out-cleaning-card v-if="false"></move-in-out-cleaning-card>
              <has-schedule-warning v-if="hasSchedule"></has-schedule-warning>
              <select-plan v-else @frequency:picked="onFrequencyPicked" :frequency="frequency"></select-plan>
              <ask-terms/>
              <button type="submit" class="btn btn-primary main-btn">Next</button>
            </form>
          </div>
        </div>
        <div class="content-wrapper booking-secondary-content d-none d-md-block">
          <desktop-summary-widget
            :info="summaryInfo"
            :promo-code="promoCode"
            @save:promo_code="$emit('save:promo_code')"
            @update:promo_code="$emit('update:promo_code', $event)"
            @remove:promo_code="$emit('remove:promo_code')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import AskTerms from '../ask_terms.vue'
import StandardCleaningCard from '../booking/standard_cleaning_card.vue'
import UpkeepCleaningCard from '../booking/upkeep_cleaning_card.vue'
import MoveInOutCleaningCard from '../booking/move_in_out_cleaning_card.vue'
import SelectPlan from '../booking/select_plan.vue'
import HasScheduleWarning from '../booking/has_schedule_warning.vue'
export default {
  components: { AskTerms, StandardCleaningCard, UpkeepCleaningCard, MoveInOutCleaningCard, SelectPlan, HasScheduleWarning },
  computed: {
    showPrevArrow() {
      return this.newProperty
    },
    ...mapGetters(['summaryInfo']),
    ...mapGetters({
      frequency: 'reservation/frequency',
      promoCode: 'reservation/promo_code',
      header: 'property/plan_selection_header',
      newProperty: 'property/newProperty',
      hasSchedule: 'property/hasSchedule',
      termsAccepted: 'client/termsAccepted'
    }),
  },
  methods: {
    onFrequencyPicked(frequency) {
      if (this.frequency == frequency) {
        this.$store.commit('reservation/SET_FREQUENCY', null)
      } else {
        this.$store.commit('reservation/SET_FREQUENCY', frequency)
      }
    },
    onSubmit() {
      if (this.termsAccepted) {
        this.$store.dispatch('toExtras')
      } else {
        this.$flasher.error('You need to accept the terms and conditions')
      }
    },
    toPrevStep() {
      this.$store.dispatch('toSignUp', null, { root: true })
    },
    ...mapActions(['resumeBooking']),
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'booking-bg'
    if (this.$store.getters['client/isDisabled']) {
      this.$flasher.error('You need to add a valid payment method to be able to book')
      this.$store.dispatch('toBilling')
      return
    }
    this.$store.commit('STOP_INDICATOR')
  }
}
</script>
