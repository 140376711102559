<template>
  <div class="selector__col">
    <span class="selector__label">{{serviceTitle}}</span>
    <div class="selector__counter counter">
      <button class="counter__btn counter__btn--increment" @click="$emit('remove', changeParam)">
        <i class="fa fa-minus"></i>
        <span class="hide">Add 1 {{areaTitle}} of Clean.</span>
      </button>
      <input class="selector__control form__control"
        type="text"
        :aria-label="serviceTitle"
        :value="qty()"
      />
      <button class="counter__btn counter__btn--decrement" @click="$emit('add', changeParam)">
        <i class="fa fa-plus"></i>
        <span class="hide">Remove 1 {{areaTitle}} of Clean.</span>
      </button>
    </div>
    <p class="selector__price-info"><span>{{price}}</span></p>
  </div>
</template>

<script>
import Base from './base.vue'
export default {
  extends: Base,
  props: {
    areaService: {
      type: Object,
      required: true
    },
    areaServiceKey: {
      type: String,
      required: true
    },
    prices: {
      type: Object,
      required: true
    }
  },
  methods: {
    qty() {
      if (this.pickedServices[this.serviceKey]) {
        if (this.pickedServices[this.serviceKey][this.areaKey]) {
          if (this.pickedServices[this.serviceKey][this.areaKey][this.areaServiceKey]) {
            return this.pickedServices[this.serviceKey][this.areaKey][this.areaServiceKey]
          }
        }
      }
      return 0
    }
  }
}
</script>
