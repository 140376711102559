<template>
  <div class="selector__col">
    <div v-if="isRemovable" class="close-action">
      <button @click="$emit('remove-area', updateParam)" class="btn btn-circle btn-light"><i class="far fa-times fa-lg"></i></button>
    </div>
    <span class="selector__label">length & width</span>
    <div class="selector__input">
      <div class="selector__input--group">
        <div class="form__field">
          <label class="hide" for="inputRugLength_0">Set {{service}} length</label>
          <input class="selector__control form__control" type="text" v-model="serviceLength">
        </div>
        <i class="fas fa-times"></i>
        <div class="form__field">
          <label class="hide" for="inputRugWidth_0">Set {{service}} width</label>
          <input class="selector__control form__control" type="text" v-model="serviceWidth">
        </div>
      </div>
      <p class="selector__price-info"><span>{{info}}</span></p>
    </div>
    <p class="selector__price-info"><span>{{price}}</span></p>
  </div>
</template>
<script>
import Base from './base.vue'
export default {
  extends: Base,
  computed: {
    updateParam() {
      return { service: this.serviceKey, area: this.areaKey, priceKey: this.priceKey }
    },
    info() {
      return this.area.info
    },
    isRemovable() {
      return !!this.area.isRemovable
    },
    serviceLength: {
      set(v) {
        var info = this.updateParam
        info.length = v
        this.$emit('update-dimension', info)
      },
      get() {
        if (this.pickedService && (this.pickedService.length != null)) {
          return this.pickedService.length
        }
        return 0
      }
    },
    serviceKey() {
      return this.service.slug
    },
    pickedService() {
      if (this.pickedServices[this.serviceKey]) {
        if (this.pickedServices[this.serviceKey][this.areaKey]) {
          return this.pickedServices[this.serviceKey][this.areaKey]
        }
      }
    },
    price() {
      return this.area.price
    },
    priceKey() {
      return this.area.priceKey
    },
    serviceWidth: {
      set(v) {
        var info = this.updateParam
        info.width = v
        this.$emit('update-dimension', info)
      },
      get() {
        if (this.pickedService && (this.pickedService.width != null)) {
          return this.pickedService.width
        }
        return 0
      }
    },
  }
}
</script>
