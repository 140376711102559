<template>
  <div id="content">
    <topheader></topheader>
    <div class="container-fluid px-5">
      <div class="row d-flex justify-content-center">
        <div class="content-wrapper mw-670">
          <div class="form-wrapper">
            <h2 class="form-head mb-4">Add your primary phone number</h2>
            <my-form :start-in-edit-mode="true">
              <template v-slot:default="{ invalid, valid, formIsReadonly }">
                <div class="row">
                  <div class="col"><phone-field v-model="phone" /></div>
                </div>
              </template>
              <template v-slot:edit-buttons="{ invalid, validated }">
                <b-button :disabled="invalid && validated" type="submit" variant="primary main-btn mt-4">Add</b-button>
              </template>
            </my-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapMutations,
  mapGetters
} from 'vuex'

import PhoneField from '@/shared/fields/phone.vue'

export default {
  components: { PhoneField },
  data() {
    return {
      phone: null
    }
  },
  computed: {
    phones_attributes() {
      return [{ label: 'Primary', number: this.phone, primary: true }]
    },
    ...mapGetters('client', ['isAuthenticated'])
  },
  methods: {
    submit() {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.commit('client/SET_PHONES', this.phones_attributes)
        this.$store.dispatch('client/update').then(() => {
          this.$store.dispatch('toPropertySelection').then(() => {
            resolve()
          })
        }, (errors) => {
          this.loading = false
          reject(errors)
        })
      })
    },
  },
  mounted() {
  }
}
</script>
