import Vue from 'vue'
import Flasher from '@/flasher'
Vue.use(Flasher)
import api from '@/clients/api'
import profileApi from '@/shared/apis/profile'
import router from '@/clients/router'

const debug = typeof DEBUG_CLIENT !== 'undefined' ? DEBUG_CLIENT : false;

const loadFromStore = function(key) {
  const json = localStorage.getItem(key)
  if (debug) {
    console.log("CLIENT loadFromStore", key, json)
  }
  if (json) {
    return JSON.parse(json)
  }
}

const saveToStore = function(key, value) {
  if (value == null) {
    if (debug) {
      console.log("CLIENT saveToStore REMOVE", key, value)
    }
    localStorage.removeItem(key)
  } else {
    if (debug) {
      console.log("CLIENT saveToStore SET", key, value)
    }
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const namespaced = true

const initialState = function() {
  return {
    hasAnyBooking: null,
    isAuthenticated: null,
    disabled: null,
    allowedCustomExtraIds: null,
    client_id: null,
    confirmation_url: null,
    locked: false,
    readonly: false,
    lockedBy: null,
    first_name: null,
    last_name: null,
    full_name: null,
    email: null,
    referral_code: null,
    short_referral_url: null,
    referred_by: null,
    referral_id: null,
    hmac: null,
    secondary_contact: null,
    secondary_email: null,
    secondary_phone: null,
    has_invalid_phone: false,
    rating_required: false,
    postpone_rating: false,
    phone: null,
    phones: [],
    termsAccepted: false,
    detectLocation: false,
    profile: null,
    properties: [],
    invitations: [],
    has_credit_card: null,
    texts_are_disabled: null,
    has_past_due_balance: null,
    wantsSitter4Paws: null,
    cards: []
  }
}

export const state = initialState
export const getters = {
  client_id: state => state.client_id,
  allowedCustomExtraIds: state => state.allowedCustomExtraIds,
  isAuthenticated: state => state.isAuthenticated,
  isDisabled: state => state.disabled,
  hasAnyBooking: state => state.hasAnyBooking,
  termsAccepted: state => state.termsAccepted,
  confirmation_url: state => state.confirmation_url,
  client_confirmed: (state) => {
    return state.confirmation_url == null
  },
  locked: state => state.locked,
  readonly: state => state.readonly,
  lockedBy: state => state.lockedBy,
  first_name: state => state.first_name,
  last_name: state => state.last_name,
  full_name: state => state.full_name,
  email: state => state.email,
  referral_code: state => state.referral_code,
  short_referral_url: state => state.short_referral_url,
  referral_id: state => state.referral_id,
  referred_by: state => state.referred_by,
  hmac: state => state.hmac,
  secondary_contact: state => state.secondary_contact,
  secondary_email: state => state.secondary_email,
  secondary_phone: state => state.secondary_phone,
  has_invalid_phone: state => state.has_invalid_phone,
  rating_required: (state) => {
    return state.rating_required && !state.postpone_rating
  },
  billingAddress: (state) => {
    if (state.profile) {
      return {
        address: state.profile.address,
        address2: state.profile.address2,
        city: state.profile.city,
        state: state.profile.state,
        country: state.profile.country,
        zip_code: state.profile.zip_code,
      }
    }
  },
  phone: state => state.phone,
  phones: state => state.phones,
  properties: state => state.properties,
  invitations: state => state.invitations,
  cards: state => state.cards,
  hasNoCard: state => state.has_credit_card !== true,
  hasPastDueBalance: state => state.has_past_due_balance,
  textsAreDisabled: state => state.texts_are_disabled,
  text_consent: state => {
    if (state.profile) {
      return state.profile.text_consent
    }
    return false
  },
  text_offers_consent: state => {
    if (state.profile) {
      return state.profile.text_offers_consent
    }
    return false
  },
  detectLocation: state => state.detectLocation,
  wantsSitter4Paws: state => state.wantsSitter4Paws,
  profile: state => state.profile,
  latitude: (state) => {
    if (state.profile) {
      return state.profile.latitude
    }
  },
  longitude: (state) => {
    if (state.profile) {
      state.profile.longitude
    }
  }
}
export const mutations = {
  RESET(state) {
    const s = initialState()
    if (debug) {
      console.log("CLIENT RESET")
    }
    Object.keys(s).forEach(key => {
      if ( (key != 'termsAccepted') && (key != 'isAuthenticated') && (key != 'hasAnyBooking') ) {
        localStorage.removeItem(key)
        state[key] = s[key]
      }
    })
  },
  SET_ATTR(state, { attr, value }) {
    state[attr] = value
    if (debug) {
      console.log('CLIENT SET_ATTR', attr, value)
    }
  },
  SET_PROFILE_ATTR(state, { attr, value }) {
    if (state.profile == null) {
      Vue.set(state, 'profile', {})
    }
    Vue.set(state.profile, attr, value)
    if (debug) {
      console.log('CLIENT SET_PROFILE_ATTR', attr, value)
    }
  },
  SET_PHONES(state, phones) {
    state.phones = phones
  },
  SET_PROPERTIES(state, properties) {
    state.properties = properties
  },
  SET_INVITATIONS(state, invitations) {
    state.invitations = invitations
  },
  SET_CARDS(state, cards) {
    state.cards = cards
  },
  SET_PROFILE(state, profile) {
    state.profile = profile
  },
  SET_IS_AUTHENTICATED(state, status) {
    if (debug) {
      console.log("SET_IS_AUTHENTICATED", status)
    }
    state.isAuthenticated = status
  },
  SET_CLIENT_DATA(state, client) {
    state.client_id = client.id
    state.allowedCustomExtraIds = client.allowed_custom_extra_ids
    state.locked = client.locked
    state.readonly = client.readonly
    state.lockedBy = client.locked_by
    state.first_name = client.first_name
    state.last_name = client.last_name
    state.full_name = client.full_name
    state.email = client.email
    state.referral_code = client.referral_code
    state.short_referral_url = client.short_referral_url
    state.hmac = client.hmac
    state.secondary_contact = client.secondary_contact
    state.secondary_email = client.secondary_email
    state.secondary_phone = client.secondary_phone
    state.has_invalid_phone = client.has_invalid_phone
    state.rating_required = client.rating_required
    state.phone = client.phone
    state.phones = client.phones
    state.hasAnyBooking = client.has_any_booking
    state.termsAccepted = client.tos_accepted
    state.confirmation_url = client.confirmation_url
    state.has_credit_card = client.has_credit_card
    state.has_past_due_balance = client.has_past_due_balance
    state.disabled = client.disabled
    state.texts_are_disabled = client.texts_are_disabled
    state.detectLocation = client.detect_location
    state.wantsSitter4Paws = client.wants_sitter4paws
    if (!state.profile) {
      Vue.set(state, 'profile', {})
    }
    state.profile.latitude = client.latitude
    state.profile.longitude = client.longitude
    state.profile.text_consent = client.text_consent
    state.profile.text_offers_consent = client.text_offers_consent
  },
  SET_CLIENT_HAS_CREDIT_CARD(state) {
    state.has_credit_card = true
  },
  POSTPONE_RATING(state) {
    state.postpone_rating = true
  }
}
export const actions = {
  reset({ commit, getters }) {
    return new Promise((resolve, reject) => {
      if (debug) {
        console.log('Client Store reset action isAuthenticated: ', getters.isAuthenticated)
      }
      if (!getters.isAuthenticated) {
        if (debug) {
          console.log('Client Store reset COMMIT RESET')
        }
        commit('RESET')
      }
      resolve()
    })
  },
  getProfile({ commit, getters }) {
    return new Promise((resolve, reject) => {
      profileApi
      .getProfile()
      .then(response => {
        commit('SET_PROFILE', response.data.profile)
        resolve()
      })
    })
  },
  getProperties({ commit, getters }) {
    return new Promise((resolve, reject) => {
      api
      .getProperties()
      .then(response => {
        commit('SET_PROPERTIES', response.data.properties)
        resolve()
      })
    })
  },
  getClientData({ commit }) {
    return new Promise((resolve, reject) => {
      api
        .getCurrentClient()
        .then(response => {
          commit('SET_CLIENT_DATA', response.data)
          resolve()
        })
        .catch(error => {
          reject(error.message)
        })
    })
  },
  getCards({ commit, getters }) {
    return new Promise((resolve, reject) => {
      api
      .getCards()
      .then(response => {
        commit('SET_CARDS', response.data.cards)
        resolve()
      })
    })
  },
  getInvitations({ commit, getters }) {
    return new Promise((resolve, reject) => {
      api
      .getInvitations()
      .then(response => {
        commit('SET_INVITATIONS', response.data.invitations)
        resolve()
      })
    })
  },
  login({ commit, dispatch, rootGetters }) {
    return new Promise((resolve, reject) => {
      api
        .getCurrentClient()
        .then(response => {
          commit('SET_CLIENT_DATA', response.data)
          commit('SET_IS_AUTHENTICATED', true)
          resolve()
        }, (errors) => {
          commit('SET_IS_AUTHENTICATED', false)
          reject(errors)
        })
    })
  },
  resume({ getters, commit }) {
  },
  signUp({ commit, dispatch, getters }) {
    let signUpParams = {
      first_name: getters.first_name,
      last_name: getters.last_name,
      email: getters.email,
      referred_by: getters.referred_by,
      referral_id: getters.referral_id,
      phone: getters.phone,
      wants_sitter4paws: getters.wantsSitter4Paws,
      context: 1
    }
      const profile_attributes = {
        text_consent: getters.text_consent,
        text_offers_consent: getters.text_offers_consent,
      }
      signUpParams.profile_attributes = profile_attributes

    // The Promise used for router redirect in Signup.vue
    return new Promise((resolve, reject) => {
      api
        .clientSignUp(signUpParams)
        .then(response => {
          commit('SET_CLIENT_DATA', response.data)
          commit('SET_IS_AUTHENTICATED', true)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  signOut({ commit, dispatch }) {
    return new Promise(resolve => {
      api.deleteSession().then(() => {
        commit('RESET')
        commit('SET_IS_AUTHENTICATED', null)
        dispatch('resetBooking', { dbg: 'client' }, { root: true }).then(() => {
          resolve();
        })
      });
    });
  },
  update({ commit, getters, rootGetters }) {
    var clientParams = {
      first_name: getters.first_name,
      last_name: getters.last_name,
      phones_attributes: getters.phones,
      email: getters.email,
      secondary_contact: getters.secondary_contact,
      secondary_phone: getters.secondary_phone,
      secondary_email: getters.secondary_email,
      wants_sitter4paws: getters.wantsSitter4Paws,
    }
    if (getters.profile) {
      const profile_attributes = {
        id: getters.profile.id,
        address: getters.profile.address,
        address2: getters.profile.address2,
        zip_code: getters.profile.zip_code,
        city: getters.profile.city,
        text_consent: getters.text_consent,
        text_offers_consent: getters.text_offers_consent,
      }
      clientParams.profile_attributes = profile_attributes
    }
    //// disable phone validation on first step
    if (rootGetters['step'] == 1) {
      clientParams.context = 'booking'
    }
    return new Promise((resolve, reject) => {
      api
        .updateClient(getters.client_id, clientParams)
        .then(response => {
          commit('SET_CLIENT_DATA', response.data)
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sendPasswordResetEmail({ commit, getters }) {
    return new Promise((resolve, reject) => {
      api
        .sendPasswordResetEmail()
        .then(response => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  sendInvitation({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      api
        .sendInvitation(params)
        .then(response => {
          resolve()
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  setAttr({ commit }, attrAndValue) {
    commit('SET_ATTR', attrAndValue)
  },
  setProfileAttr({ commit }, attrAndValue) {
    commit('SET_PROFILE_ATTR', attrAndValue)
  },
  setTermsAccepted( { commit }) {
    return new Promise((resolve, reject) => {
      api.acceptTerms().then(() => {
        commit('SET_ATTR', { attr: 'termsAccepted', value: true })
      })
    })
  },
  toFixProfile({ commit }) {
    router.push({ path: `/profile/edit` })
  },
  toAddPhone({ commit }) {
    router.push({ path: `/add_phone` })
  },
  toRating({ commit }) {
    router.push({ path: `/rate` })
  },
  postponeRating({ commit }) {
    commit('POSTPONE_RATING')
    router.push({ path: `/` })
  }
}
