<template>
  <div>
    <topheader></topheader>
    <div class="booking-confirm-section mb-2">
      <div class="booking-confirm-container">
        <img class="img-fluid img-map-rounded shadow" :src="mapImgSrc">
        <h2>{{title}}</h2>
        <p>{{confirmationText}}</p>
        <a v-if="accountConfirmed" href="/calendar" class="btn btn-primary mb-4 mt-1 main-btn" role="button" style="display: table">Calendar</a>
        <a v-else :href="confirmationUrl" class="btn btn-primary main-btn mt-1" role="button" style="display: table">Activate my account</a>
        <a v-if="hasNoCard" @click="wantsCardModal = true" href="#" class="btn btn-gray main-btn mt-1 mx-auto" role="button" style="display: table">Add Credit Card</a>
        <a href="#" @click.prevent="wantsConfirmationModal">See {{confirmationDetails}} <i class="fal fa-chevron-right ml-1"></i></a>
        <div class="my-2 text-center">
          <a class="text-light-color cursor-pointer" href="https://www.wavehousecleaning.com/cancellation-policy/" target="_blank"><i class="fal fa-info-circle"></i> Cancellation Policy</a>
        </div>
      </div>
      <a href="https://wavehousecleaning.com/frequently-asked-questions/" class="white-link d-block text-center">
        Get Help <i class="fal fa-chevron-right ml-1"></i>
      </a>
    </div>
    <card-modal
      title="Booking completed! Please update your billing!"
      :show="showCardModal"
      :customer="customer"
      :client-id="clientId"
      :stripe-info="stripeInfo"
      @close="wantsCardModal=false"
      @updated="onCardsUpdated"
      @start-indicator="startIndicator"
      @stop-indicator="loading = false"
    ></card-modal>
  </div>
</template>
<script>
import api from '@/clients/api'
import router from '../router'
import { mapActions, mapGetters } from 'vuex'
import CardModal from '@/modals/card_modal.vue'

export default {
  components: { CardModal },
  computed: {
    showCardModal() {
      return this.wantsCardModal && this.stripeInfo != null && this.hasNoCard
    },
    ...mapGetters(['bookingRequestId', 'summaryInfo']),
    ...mapGetters({
      finishedBooking: 'finishedBooking',
      clientId: 'client/client_id',
      accountConfirmed: 'client/client_confirmed',
      confirmationDetails: 'reservation/confirmation_details',
      confirmationText: 'reservation/confirmation_text',
      confirmationUrl: 'client/confirmation_url',
      hasNoCard: 'client/hasNoCard',
      mapImgSrc: 'property/map_img_src',
      propertyId: 'property/location_id',
      title: 'reservation/confirmation_title'
    }),
    totalPriceForGTM() {
      let totalPrice = 0;

      // For Plan Price
      if (this.summaryInfo.cleaning == 'CarpetCleaning') {
        totalPrice += this.summaryInfo.carpetCleaningPrice;
      }
      if (this.summaryInfo.prices && this.summaryInfo.requestedCleaning) {
        totalPrice =  this.summaryInfo.prices[this.summaryInfo.requestedCleaning]
      }

      // For Extras
      let extraCost = 0
      if (this.summaryInfo.pickedExtras) {
        for(var extraIdx in this.summaryInfo.pickedExtras) {
          const extra = this.summaryInfo.pickedExtras[extraIdx]
          if (extra) {
            let usedQty = extra.quantity - extra.includedQty
            if (usedQty < 0) { usedQty = 0 }
            extraCost = extraCost + (extra.basePrice + extra.perCost * usedQty)
          }
        }
      }
      totalPrice += extraCost;

      // For Parking
      if (this.summaryInfo.chargeParkingFee) {
        totalPrice += 4
      }

      return totalPrice.toFixed(2)
    }
  },
  data() {
    return {
      stripeInfo: null,
      customer: null,
      wantsCardModal: true
    }
  },
  methods: {
    getPaymentInfo() {
      this.$store.commit('START_INDICATOR')
      api.getClientPaymentInfoForProperty(this.clientId, this.propertyId).then(response => {
        this.$store.commit('STOP_INDICATOR')
        this.customer = response.data.customer
        this.stripeInfo = response.data.area
      })
    },
    startIndicator() {
      this.$store.commit('START_INDICATOR')
    },
    onCardsUpdated() {
      this.$store.commit('client/SET_CLIENT_HAS_CREDIT_CARD')
      this.wantsCardModal = false
    },
    ...mapActions(['wantsConfirmationModal'])
  },
  mounted() {
    if (!this.finishedBooking) {
      router.push({ path: '/' })
    }
    this.getPaymentInfo()

    if (process.env.NODE_ENV == 'production') {
      if (!this.$store.getters['client/hasAnyBooking']) {
        this.$gtm.trackEvent({
          event: 'new-client-booking',
          booking: {
            currency: 'USD',
            value: this.totalPriceForGTM,
            booking_id: this.bookingRequestId
          }
        });
      } else {
        this.$gtm.trackEvent({
          event: 'recurrent-client-booking',
          booking: {
            currency: 'USD',
            value: this.totalPriceForGTM,
            booking_id: this.bookingRequestId
          }
        });
      }
      this.$gtm.trackView('booking-confirmation', '/book_reservation/confirmation');
    }
  }
}
</script>
