<template>
  <div id="content">
    <topheader></topheader>
    <div class="page-wrapper">
      <step-indicator />
    </div>
    <div class="container mw-670">
      <h2 class="text-center">WHAT CAN WE CLEAN FOR YOU?</h2>
      <div class="card-group text-secondary">
        <div class="card border-light rounded m-3">
          <div class="card-body">
            <h2 class="card-title sub-head text-center">House Cleaning</h2>
            <img class="mx-auto d-block img-fluid" width="150px" src="@images/book-confirm-image01.png">
            <p class="card-text text-center">
              We offer house cleaning services, bedrooms and all living spaces, kitchen and more.
              <br />
              <a href="https://www.wavehousecleaning.com/house-cleaning/" target="_blank" class="text-center">Read more.</a>
            </p>
          </div>
          <div class="card-footer bg-white border-0 text-center">
            <button @click="pickService('PropertyCleaning')" class="btn main-btn">Continue</button>
          </div>
        </div>
        <div class="card border-light rounded m-3">
          <div class="card-body">
            <h2 class="card-title sub-head text-center">Hot Water Extraction</h2>
            <img class="mx-auto d-block img-fluid" width="150px" src="@images/book-carpet-cleaning.png">
            <p class="card-text text-center">
              We offer cleaning services for carpet cleaning, tile and grout, upholstery.
              <br />
              <strong class="blue-text">California only.</strong>
              <br />
              <a href="https://www.wavehousecleaning.com/hot-water-extraction/" target="_blank" class="text-center">Read more.</a>
            </p>
          </div>
          <div class="card-footer bg-white border-0 text-center">
            <button @click="pickService('CarpetCleaning')" class="btn main-btn">Continue</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapMutations,
  mapGetters
} from 'vuex'

export default {
  components: { },
  computed: {
    ...mapGetters('client', ['isAuthenticated', 'phones', 'has_invalid_phone', 'hasPastDueBalance']),
    ...mapGetters(['loading'])
  },
  data() {
    return {
      areas: [
      ],
      submitStatus: null,
    }
  },
  methods: {
    pickService(serviceType) {
      if (serviceType == 'PropertyCleaning') {
        this.$store.commit('PICK_PROPERTY_CLEANING_SERVICE')
        this.$store.dispatch('toPlanSelection')
        this.$store.dispatch('rememberValues')
        return
      }
      if (serviceType == 'CarpetCleaning') {
        window.location = "https://98zn8v65yoa.typeform.com/to/q1rmj4jc"
        /*
        this.$store.commit('PICK_CARPET_CLEANING_SERVICE')
        this.$store.dispatch('toCarpetCleaning')
        this.$store.dispatch('rememberValues')
        */
        return
      }
      console.error('Invalid serviceType', serviceType)
    },
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
        return
      } else {
        this.submitStatus = 'PENDING'
        this.submit()
      }
    },
    submit() {
      return new Promise((resolve, reject) => {
        if (this.isAuthenticated) {
          this.$store.dispatch('client/update').then(() => {
            this.$store.dispatch('property/check').then(() => {
              this.$store.dispatch('property/create_or_update', 'booking').then(() => {
                this.$store.dispatch('toServiceSelection')
                resolve()
              })
            })
          })
        } else {
          this.$store.dispatch('startBooking')
          this.$store.dispatch('client/signUp').then(() => {
            this.$store.dispatch('property/create_or_update').then(() => {
              this.$store.dispatch('toServiceSelection')
              resolve()
            })
          }, (errors) => {
            this.$flasher.error(errors)
            reject(errors)
          })
        }
      })
    },
    ...mapActions({
      setClientAttr: 'client/setAttr',
      setPropertyAttr: 'property/setAttr'
    })
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'booking-bg'
    if (this.hasPastDueBalance) {
      this.$router.push({ path: '/billing' })
      return
    }
    if ( (this.phones == null) || (this.phones.length == 0) || (this.has_invalid_phone) ) {
      this.$store.dispatch('client/toAddPhone')
      this.$flasher.error('You need to add your phone before booking')
      return
    }
    this.$store.commit('STOP_INDICATOR')
    this.$store.dispatch('hideMenu', null, { root: true })
  }
}
</script>
