<template>
  <div id="content">
    <topheader></topheader>
    <div class="container-fluid tab-header">
      <div class="outer-wrapper pb-0">
        <div class="text-center clearfix pb-30 pt-5">
          <no-card-warning :no-card="clientHasNoCard"></no-card-warning>
          <div class="d-inline-block main-head">Manage Property</div>
          <locker-widget v-if="property" @update:locked="onLockedUpdate" v-bind="lockingParams" record-class="location" :record-id="propertyId" ref="locker" />
        </div>
        <tabs ref="tabs" :tabs="tabs" @change="tabChange"></tabs>
      </div>
    </div>
    <div class="container-fluid">
      <div class="outer-wrapper tab-content pt-3" v-if="tab">
        <spinner :show="loading" message="Saving"></spinner>
        <keep-alive>
          <component v-bind:is="componentForTab" :property="property" :property-id="propertyId" :allowed-extra-ids="allowedExtraIds" @save-extras="saveExtras" @book="bookProperty" @delete="deleteProperty" @go-tab="goTab" @refresh-notes="updateNotes" @update:edit-mode="onUpdateEditMode" :user="client" :user-id="client.id" :is-client="true" :readonly="readonly" :locked-css="lockedCss" @open-visit="openVisit" :go-to-tab="visitTab"></component>
        </keep-alive>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import PropertyManagement from '@/properties/management.vue'
import locking_mixin from '@/shared/locking_mixin'
import api from '@/clients/api'
import $ from 'jquery'
import no_card_warning_mixin from '@/shared/no_card_warning_mixin'
import {
  mapActions
} from 'vuex'

export default {
  mixins: [ locking_mixin, no_card_warning_mixin ],
  extends: PropertyManagement,
  computed: {
    propertyId() {
      return this.$route.params.id * 1
    }
  },
  methods: {
    beforeRouteLeave(to, from, next) {
      const url = '/api/properties/' + this.propertyId + '/notify'
      this.$http.put(url)
    },
    onUpdateEditMode(newMode) {
      this.hasPendingChanges = newMode
      this.onEditModeChange(newMode)
    },
    bookProperty() {
      this.locationPicked(this.property.id).then(() => {
          this.$store.dispatch("toPlanSelection")
      }, error => {
        this.$store.dispatch('property/toFixProperty', this.property.id)
        this.$flasher.error(error)
      })
    },
    deleteProperty() {
      this.$dialog
        .confirm('Are you sure you want to delete this property?', {
          okText: 'Yes',
          cancelText: 'No'
        })
        .then((dialog) => {
          api.deleteProperty(this.property.id).then(() => {
            this.$router.push({
              path: '/properties'
            })
            this.$flasher.success('Property successfully deleted')
          }, (response) => {
            this.$flasher.error(response.response.data.errors)
          })
        })
    },
    ...mapActions({
      locationPicked: "property/pickLocation"
    })
  },
  created() {
    window.onbeforeunload = () => {
      if (this.hasPendingChanges) {
        return true
      }
    }
  },
  mounted() {
    //// from route
    this.initTab = this.$attrs.initTab
    this.visitTab = this.$attrs.visitTab
    document.getElementsByTagName('body')[0].className = 'white-theme'
  }

}
</script>
