<template>
  <div>
    <h4 class="form-head mt-5 mb-4">Select if desired</h4>
    <div class="btn-set">
      <button v-for="plan in plans" type="button" @click="$emit('frequency:picked', plan)" class="btn btn-default text-capitalize" :class="{ 'active': isActive(plan) }">{{plan}}</button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    frequency: {
      type: String
    }
  },
  data() {
    return {
      plans: Window.plans
    }
  },
  methods: {
    isActive(plan) {
      return this.frequency == plan
    },
  }
}
</script>
