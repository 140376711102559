import store from '@/clients/store'
import { mapGetters } from 'vuex'
import NoCardWarning from './no_card_warning.vue'
export default {
  components: { NoCardWarning },
  computed: {
    ...mapGetters({
      clientHasNoCard: 'client/hasNoCard'
    })
  }
}
