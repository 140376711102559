<template>
  <div v-cloak id="content">
    <topheader></topheader>
    <div class="page-wrapper">
      <div class="container">
        <no-card-warning :no-card="clientHasNoCard"></no-card-warning>
      </div>
    </div>
    <calendar :client-id="clientId"><div class="v-spinner" style="position: absolute; left: 50%"><div class="v-clip" style="height: 35px; width: 35px; border-width: 2px; border-style: solid; border-color: rgb(93, 197, 150) rgb(93, 197, 150) transparent; border-radius: 100%; background: transparent;"></div></div></calendar>
  </div>
</template>
<script>
import no_card_warning_mixin from '@/shared/no_card_warning_mixin'
import Calendar from '@/calendar/ClientCalendar.vue'

export default {
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (!vm.$store.getters['client/hasAnyBooking']) {
        next({ path: '/' })
      }
    })
  },
  components: { Calendar },
  mixins: [ no_card_warning_mixin ],
  computed: {
    clientId() {
      return this.$store.getters['client/client_id']
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'white-theme'
    this.$store.commit('STOP_INDICATOR')
  }
}
</script>
