<template>
  <div id="content">
    <topheader></topheader>
    <div class="page-wrapper">
      <div class="box-wrapper">
        <!-- how it works -->
        <h2 class="main-head text-center pb-3"><i class="fas fa-award fa-2x"></i></h2>
        <h3 class="text-center">Earn with WHC!</h3>
        <p class="text-center px-4 mb-1">Share you code with your friends and receive a bonus reward as a thanks from us for the referral.</p>
        <p class="text-center py-0">
          <a href="https://wavehousecleaning.com/terms-and-conditions/" target="_blank" class="gray-text text-small">View full terms & conditions</a>
        </p>
        <!-- end how it works  -->
        <!-- Referral Link -->
        <div class="row d-flex justify-content-center">
          <div class="col-md-7" id="link">
            <div class="mx-auto">
              <b-input-group size="sm" >
                <b-form-input v-on:focus="$event.target.select()"  ref="myinput" :value="shortReferralUrl" readonly></b-form-input>
                <template #append>
                  <b-button variant="success" @click="copy">
                    <i class="fal fa-link"></i>
                  </b-button>
                </template>
              </b-input-group>
            </div>
          </div>
        </div>
        <!-- end Referral Link -->
        <hr>
        <h2 class="text-center text-bold">Rewards Collected <strong>{{ discount_points }}pts</strong></h2>
        <p class="text-center py-0 my-0">
          <a v-b-toggle.sidebar-right class="gray-text text-small cursor-pointer">How it works?</a>
        </p>
        <div class="row d-flex justify-content-center">
          <div class="mt-0 mb-4">
            <button type="submit" class="btn main-btn mt-4 btn-primary">Claim Your Points</button>
          </div>
        </div>
        <!-- Invited Friends -->
        <h2 class="text-center text-bold mt-4">Refer your Friend</h2>
        <!-- table -->
        <div v-show="invitations.length > 0">
          <b-table :items="invitations" :fields="fields"></b-table>
        </div>
        <!-- end Invited Friends -->
        <!--  Form with name and email -->
        <div class="mt-0 mb-4">
          <my-form noButtons ref="myform">
            <div class="row">
                <div class="col-sm-6"><first-name-field v-model="first_name" label="Name" /></div>
                <div class="col-sm-6"><last-name-field v-model="last_name" rules="min:7" :required="false" /></div>
                <div class="col-sm-12"><email-field v-model="email" /></div>
            </div>
            <button type="submit" class="btn main-btn mt-4 btn-primary my-0">Send Invitation</button>
          </my-form>
        </div>
        <!-- End Form -->
        <!-- Sidebar how it work -->
        <b-sidebar id="sidebar-right" title="How it works?" right shadow>
            <div class="px-3 py-2">
              <h2>1. Refer a Friend:</h2>
              <p class="pl-3">
                🌟 Exciting news! Spread the good vibes by referring friends to Wave House Cleaning. When they complete their cleaning, you both ride the wave of rewards!
              </p>
              <h2>2. Get Rewarded:</h2>
              <p class="pl-3">
                💖 Your friend enjoys a fantastic credit – it could be a percentage discount or a cool dollar credit – on their first cleaning. As a big thank you, you also score a credit for your next cleaning.
              </p>
              <h2>3. How to Refer:</h2>
              <p class="pl-3">
                🚀 It's super easy! Just click the "Refer a Friend" button in our emails or messages. You're on your way to clean homes and awesome credits.
              </p>
              <h2>4. Watch the Waves:</h2>
              <p class="pl-3">
                🌊 Sit back, relax, and enjoy the waves. We'll keep you in the loop. Once your referred friend finishes their cleaning, you'll get a confirmation, and your credit is ready to use.
              </p>
              <h2>5. Exclusive Credits:</h2>
              <p class="pl-3">
                🌈 Your earned credit is exclusive to you and comes in various forms – think percentage discounts or chill dollar credits. We'll let you know the specifics when you redeem it.
              </p>
              <h2>6. Stay Informed:</h2>
              <p class="pl-3">
                📣 We value your participation! If there's any exciting news or updates to the program, count on us to keep you in the loop.
              </p>
              <h2>7. Act Fast:</h2>
              <p class="pl-3">
                ⏰ Redeem your credit within 90 days. It's our way of saying thank you for being an awesome part of our Wave House Cleaning community.
              </p>
              <h2>8. Enjoy the Clean Vibes:</h2>
              <p class="pl-3">
                ✨ Let's make your home sparkle together! Dive into the clean vibes and embrace the flexibility of credits. Thanks for being a zen-loving, referral-vibes superstar at Wave House Cleaning! 🏡🌿
              </p>
            </div>
        </b-sidebar>
        <!-- End Sidebar how it work -->
      </div>
    </div>
  </div>
</template>

<script>

import FirstNameField from '@/shared/fields/first_name.vue'
import LastNameField from '@/shared/fields/last_name.vue'
import EmailField from '@/shared/fields/email.vue'

export default {
  components: {
    FirstNameField,
    LastNameField,
    EmailField
  },
  data() {
    return {
      loading: false,
      first_name: null,
      last_name: null, 
      email: null,
      editMode: false,
      discount_points: 0,
      fields: ['name', 'email', 'status'],
    }
  },
  computed: {
    full_name() {
      return this.$store.getters['client/full_name']
    },
    referral_code() {
      return this.$store.getters['client/referral_code']
    },
    shortReferralUrl() {
      return this.$store.getters['client/short_referral_url']
    },
    invitations() {
      return this.$store.getters['client/invitations']
    },
  },
  methods: {
    copy() {
      this.$refs.myinput.focus();
      document.execCommand('copy');
      this.$flasher.success('URL Copied!')
    },
    getInvitations() {
      this.loading = true
      this.$store.dispatch('client/getInvitations').then(() => {
        this.loading = false
      })
    },
    submit() {
      this.loading = true
      return new Promise((resolve, reject) => {
        const params = {
            first_name: this.first_name,
            last_name: this.last_name,
            email: this.email
        }

        this.$store.dispatch('client/sendInvitation', params).then(() => {
          this.loading = false
          this.first_name = null
          this.last_name = null
          this.email = null
          this.$refs.myform.resetForm()
          this.getInvitations()
          resolve()
        }, (errors) => {
          reject(errors)
        })
      })
    }
  },
  mounted() {
    document.getElementsByTagName("body")[0].className = "booking-bg"
    this.getInvitations()
  }
}
</script>
