<template>
  <div id="content">
    <topheader></topheader>
    <div class="container-fluid px-5">
      <div class="row justify-content-center">
        <div class="col-sm form-wrapper mw-670">
          <h2 class="form-head mb-4">New Location</h2>
          <my-form :readonly="readonly" :start-in-edit-mode="true">
            <template v-slot:default="{ invalid, valid, formIsReadonly }">
              <div class="row">
                <div class="col-12"><address-fields :value="fullAddress" :address2="address2" @update="onAddressUpdate" /></div>
              </div>
              <has-pet-question v-model="hasPet" @update:wants-sitter-paws="onUpdateWantsSitter4Paws" :wants-sitter4-paws="wantsSitter4Paws"></has-pet-question>
              <property-slider @update="sliderUpdate"></property-slider>
            </template>
            <template v-slot:edit-buttons="{ invalid, validated }">
              <b-button :disabled="invalid && validated" type="submit" variant="primary main-btn mt-4">Add New Location</b-button>
            </template>
          </my-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapMutations,
  mapGetters
} from 'vuex'

import AddressFields from '@/shared/address_fields.vue'
import PropertySlider from '@/properties/shared/slider.vue'
import HasPetQuestion from '../has_pet_question.vue'
import locking_mixin from '@/shared/locking_mixin'

export default {
  mixins: [ locking_mixin ],
  components: { AddressFields, PropertySlider,HasPetQuestion },
  data() {
    return {
      fullAddress: null
    }
  },
  computed: {
    address: {
      get() {
        return this.$store.getters['property/address']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'address', value: v })
      }
    },
    address2: {
      get() {
        return this.$store.getters['property/address2']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'address2', value: v })
      }
    },
    state: {
      get() {
        return this.$store.getters['property/state']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'state', value: v })
      }
    },
    city: {
      get() {
        return this.$store.getters['property/city']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'city', value: v })
      }
    },
    zip_code: {
      get() {
        return this.$store.getters['property/zip_code']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'zip_code', value: v })
      }
    },
    bedrooms: {
      get() {
        return this.$store.getters['property/bedrooms']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'bedrooms', value: v })
      }
    },
    bathrooms: {
      get() {
        return this.$store.getters['property/bathrooms']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'bathrooms', value: v })
      }
    },
    square_feet: {
      get() {
        return this.$store.getters['property/square_feet']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'square_feet', value: v })
      }
    },
    hasPet: {
      get() {
        return this.$store.getters['property/hasPet']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'hasPet', value: v })
      }
    },
    ...mapGetters('client', ['isAuthenticated', 'wantsSitter4Paws'])
  },
  methods: {
    onAddressUpdate({ field, value }) {
      this[field] = value
    },
    onUpdateWantsSitter4Paws(options) {
      if ((options.attr == 'wantsSitter4Paws') ) {
        this.$store.commit('client/SET_ATTR', options)
      }
    },
    sliderUpdate(attrs) {
      this.bathrooms = attrs.bathrooms
      this.bedrooms = attrs.bedrooms,
      this.square_feet = attrs.square_feet
    },
    submit() {
      this.loading = true
      return new Promise((resolve, reject) => {
        if (this.hasPet == null) {
          reject({ errors: [ 'Please answer the question'] })
          return
        }
        this.createProperty().then(() => {
          if (process.env.NODE_ENV == 'production') {
            this.$gtm.trackEvent({
              event: 'client-added-new-property',
            });
          }
          this.$store.dispatch('client/update').then(() => {
            this.$store.dispatch('toPlanSelection')
            resolve()
          })
        }, (errors) => {
          this.loading = false
          reject(errors)
        })
      })
    },
    ...mapActions({
      createProperty: 'property/create_or_update',
      setClientAttr: 'client/setAttr',
      setPropertyAttr: 'property/setAttr'
    })
  },
  mounted() {
    this.$store.dispatch('property/reset').then(() => {
      document.getElementsByTagName('body')[0].className = 'booking-bg'
      this.$store.commit('STOP_INDICATOR')
    })
  }
}
</script>
