import Vue from 'vue'
import api from '@/clients/api'
import router from '@/clients/router'
import { formatAddress } from '@/utils'

const debug = typeof DEBUG_PROPERTY !== 'undefined' ? DEBUG_PROPERTY : false;

const loadFromStore = function(key) {
  const json = localStorage.getItem(key)
  if (debug) {
    console.log("PROPERTY loadFromStore", key, json)
  }
  if (json) {
    return JSON.parse(json)
  }
}

const saveToStore = function(key, value) {
  if (value == null) {
    if (debug) {
      console.log("PROPERTY saveToStore REMOVE", key, value)
    }
    localStorage.removeItem(key)
  } else {
    if (debug) {
      console.log("PROPERTY saveToStore SET", key, value)
    }
    localStorage.setItem(key, JSON.stringify(value))
  }
}

const initialState = function() {
  return {
    location_id: null,
    hweEnabled: false,
    note_id: null,
    note: null,
    extras: {},
    extrasCost: 0,
    saveExtras: null,
    bedrooms: 0,
    bathrooms: 1,
    gate_code: null,
    alarm_code: null,
    lockbox: null,
    square_feet: 0,
    hasFreeParking: false,
    hasPet: null,
    askParking: false,
    address: null,
    address2: null,
    state: null,
    city: null,
    map_img_src: null,
    plan_selection_header: '',
    zip_code: null,
    teamRequired: null,
    isNew: false,
    hasSchedule: false,
    free_plan_selection: false,
    incomplete: null,
    details: '',
    has_credit_card: null,
  }
}
export const namespaced = true

export const state = initialState

export const getters = {
  location_id: state => state.location_id,
  note_id: state => state.note_id,
  note: state => state.note,
  extras: state => state.extras,
  extrasCost: state => state.extrasCost,
  saveExtras: state => state.saveExtras,
  isLocationIncomplete: state => state.incomplete,
  askParking: state => state.askParking,
  hasFreeParking: state => state.hasFreeParking,
  hasPet: state => state.hasPet,
  requireParkingInstructions: (state) => {
    return state.askParking && state.hasFreeParking
  },
  chargeParkingFee: (state) => {
    return state.askParking && !state.hasFreeParking
  },
  map_img_src: state => state.map_img_src,
  plan_selection_header: state => state.plan_selection_header,
  free_plan_selection: state => state.free_plan_selection,
  bedrooms: state => state.bedrooms,
  bathrooms: state => state.bathrooms,
  gate_code: state => state.gate_code,
  alarm_code: state => state.alarm_code,
  lockbox: state => state.lockbox,
  square_feet: state => state.square_feet,
  details: state => state.details,
  fullAddress: (state, getters) => {
    return formatAddress(getters.addressObject).full
  },
  addressObject: (state) => {
    return { address: state.address, address2: state.address2, city: state.city, zip_code: state.zip_code, state: state.state }
  },
  address: state => state.address,
  address2: state => state.address2,
  state: state => state.state,
  city: state => state.city,
  teamRequired: state => state.teamRequired,
  zip_code: state => state.zip_code,
  canPickPlan: (state) => {
    return !state.isNew || state.free_plan_selection
  },
  newProperty: state => state.isNew,
  hasSchedule: state => state.hasSchedule,
  has_credit_card: state => state.has_credit_card === true,
  hweEnabled: state => state.hweEnabled
}
export const mutations = {
  RESET(state) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      state[key] = s[key]
      localStorage.removeItem(key)
      if (debug) {
        console.log("PROPERTY RESET", key, s[key])
      }
    })
  },
  SET_ATTR(state, { attr, value }) {
    state[attr] = value
    saveToStore(attr, value)
  },
  SET_NOTE(state, note) {
    state.note = note
  },
  SET_EXTRAS(state, extras) {
    if (extras == null) {
      extras = {}
    }
    for(var extraId in extras) {
      const quantity = extras[extraId]
      if (quantity > 0) {
        Vue.set(state.extras, extraId, quantity)
      } else {
        Vue.delete(state.extras, extraId)
      }
    }
    saveToStore('extras', extras)
  },
  SET_EXTRAS_COST(state, cost) {
    state.extrasCost = cost
    saveToStore('extrasCost', cost)
  },
  SET_SAVE_EXTRAS(state, value) {
    state.saveExtras = value
    saveToStore('saveExtras', value)
  },
  SET_HAS_CREDIT_CARD(state) {
    state.has_credit_card = true
  }
}
export const actions = {
  check({commit, dispatch, getters}) {
    if (debug) {
      console.log('Property Store check action')
    }
    dispatch('setIndicatorMessage', 'Getting prices', { root: true })
      return new Promise((resolve, reject) => {
        api
          .checkProperty(getters.address, getters.address2, getters.city, getters.state, getters.zip_code, getters.bedrooms, getters.bathrooms, getters.square_feet, 'booking')
          .then(response => {
            commit('SET_ATTR', { attr: 'isNew', value: response.data.isNew })
            commit('SET_ATTR', { attr: 'hasSchedule', value: response.data.has_schedule })
            dispatch('resetIndicatorMessage', null, { root: true })
            //commit('setClientData', response.data)
//            this.dispatch('getTackleBoxItems')
            resolve()
          }, (errors) => {
           reject(errors)
          })
          .catch(error => {
            console.error(error)
            // if the request fails, clear client
            dispatch('client/reset', null, { root: true })
            reject(error.message)
          })
      })
    },
  reset({ commit, rootGetters }) {
    return new Promise((resolve, reject) => {
      commit('RESET')
      resolve()
    })
  },
  create_or_update({ commit, dispatch, getters, rootGetters }) {
    const property = {
      address: getters.address,
      address2: getters.address2,
      city: getters.city,
      state: getters.state,
      zip_code: getters.zip_code,
      bedrooms: getters.bedrooms,
      bathrooms: getters.bathrooms,
      square_feet: getters.square_feet,
      has_free_parking: getters.hasFreeParking,
      has_pet: getters.hasPet,
      client_id: rootGetters['client/client_id'],
      context: 'booking'
    }
    dispatch('setIndicatorMessage', 'Saving', { root: true })
    if (getters.location_id) {
      return new Promise((resolve, reject) => {
      api
        .updateProperty(getters.location_id, property)
        .then(response => {
          commit('SET_ATTR', { attr: 'note_id', value: response.data.note_id })
          commit('SET_ATTR', { attr: 'teamRequired', value: response.data.team_required })
          commit('SET_ATTR', { attr: 'plan_selection_header', value: response.data.plan_selection_header })
          commit('SET_ATTR', { attr: 'details', value: response.data.details })
          dispatch('setPrices', response.data.prices, { root: true })
          dispatch('reservation/setRequestedCleaning', response.data.suggested_cleaning, { root: true })
          dispatch('resetIndicatorMessage', null, { root: true })
          resolve()
        })
        .catch(error => {
          console.error(error)
          reject(error.response.data.errors)
        })
      })
    }

    return new Promise((resolve, reject) => {
    api
      .createProperty(property)
      .then(response => {
        commit('SET_ATTR', { attr: 'location_id', value: response.data.id })
        saveToStore('location_id', response.data.id)
        commit('SET_ATTR', { attr: 'note_id', value: response.data.note_id })
        commit('SET_ATTR', { attr: 'teamRequired', value: response.data.team_required })
        commit('SET_ATTR', { attr: 'plan_selection_header', value: response.data.plan_selection_header })
        commit('SET_ATTR', { attr: 'details', value: response.data.details })
        commit('SET_ATTR', { attr: 'isNew', value: response.data.isNew })
        commit('SET_ATTR', { attr: 'hasSchedule', value: response.data.has_schedule })
        commit('SET_ATTR', { attr: 'incomplete', value: response.data.incomplete })
        commit('SET_ATTR', { attr: 'has_credit_card', value: response.data.has_credit_card })
        commit('SET_ATTR', { attr: 'hasFreeParking', value: response.data.has_free_parking })
        commit('SET_ATTR', { attr: 'hasPet', value: response.data.has_pet })
        commit('SET_ATTR', { attr: 'hweEnabled', value: response.data.hwe_enabled })
        commit('SET_ATTR', { attr: 'askParking', value: response.data.askParking })
        dispatch('setPrices', response.data.prices, { root: true })
        dispatch('reservation/setRequestedCleaning', response.data.suggested_cleaning, { root: true })
        dispatch('resetIndicatorMessage', null, { root: true })
        dispatch('startBooking', null, { root: true })
        dispatch('ensureNote')
//          router.push({ name: 'plan' })
        resolve()
      }, (errors) => {
        reject(errors)
      })
      .catch(error => {
        console.error(error)
        reject(error.response.data.errors)
      })
    })


  },
  saveNoteId({ commit, getters }) {
    return new Promise((resolve, reject) => {
      api
        .updateProperty(getters.location_id, { note_id: getters.note_id, context: 'booking' })
        .then(response => {
          resolve()
        })
        .catch(error => {
          console.error(error)
          reject(error.response.data.errors)
        })
    })
  },
  setAttr({ commit }, attrAndValue) {
    commit('SET_ATTR', attrAndValue)
  },
  addNewProperty({ commit }) {
    router.push({ name: 'new-property' })
  },
  toFixProperty({ commit }, locationId) {
    router.push({ path: `/locations/${locationId}/edit` })
  },
  toManageProperties({ commit }) {
    window.location = '/properties'
  },
  ensureNote({ commit, dispatch, getters }) {
    if (getters.note_id) {
      return new Promise((resolve, reject) => {
        api
          .getNote(getters.note_id)
          .then(response => {
            commit('SET_NOTE', response.data.notes)
            commit('SET_ATTR', { attr: 'note_id', value: response.data.notes.id })
            resolve()
          })
        })
    } else {
      return new Promise((resolve, reject) => {
        api
          .createNote(getters.location_id)
          .then(response => {
            commit('SET_ATTR', { attr: 'note_id', value: response.data.notes.id })
            dispatch('saveNoteId').then(() => {
              commit('SET_NOTE', response.data.notes)
              resolve()
            })
          })
      })
    }
  },
  saveNote({ commit, getters }, params) {
    return new Promise((resolve, reject) => {
      api
        .saveNote(getters.note.id, params)
        .then(response => {
          commit('SET_NOTE', response.data.notes)
          resolve()
        })
      })
  },
  pickLocation({ commit, getters, dispatch, rootGetters }, locationId) {
    return new Promise((resolve, reject) => {
      api
        .getProperty(locationId, 'pick-location')
        .then(response => {
          commit('SET_ATTR', { attr: 'incomplete', value: response.data.incomplete })
          if (!rootGetters['isBooking']) {
            if (response.data.invalid) {
              reject('The property has invalid rooms and/or area or missing address, please fix before booking')
              return
            }
          }
          commit('SET_ATTR', { attr: 'location_id', value: response.data.id })
          saveToStore('location_id', response.data.id)
          commit('SET_ATTR', { attr: 'note_id', value: response.data.note_id })
          dispatch('ensureNote')
          commit('SET_ATTR', { attr: 'isNew', value: response.data.isNew })

          commit('SET_ATTR', { attr: 'bathrooms', value: response.data.bathrooms })
          commit('SET_ATTR', { attr: 'bedrooms', value: response.data.bedrooms })
          commit('SET_ATTR', { attr: 'square_feet', value: response.data.square_feet })
          commit('SET_ATTR', { attr: 'hasFreeParking', value: response.data.has_free_parking })
          commit('SET_ATTR', { attr: 'hasPet', value: response.data.has_pet })
          commit('SET_ATTR', { attr: 'hweEnabled', value: response.data.hwe_enabled })
          commit('SET_ATTR', { attr: 'askParking', value: response.data.askParking })

          if (!getters.isLocationIncomplete) {
            commit('SET_ATTR', { attr: 'address', value: response.data.address })
            commit('SET_ATTR', { attr: 'address2', value: response.data.address2 })
            commit('SET_ATTR', { attr: 'city', value: response.data.city })
            commit('SET_ATTR', { attr: 'state', value: response.data.state })
          }
          commit('SET_ATTR', { attr: 'zip_code', value: response.data.zip_code })
          commit('SET_ATTR', { attr: 'teamRequired', value: response.data.team_required })
          commit('SET_ATTR', { attr: 'has_credit_card', value: response.data.has_credit_card })
          if (Object.keys(getters.extras).length == 0) {
            commit('SET_EXTRAS', response.data.extras)
          }
          if (getters.saveExtras == null) {
            commit('SET_SAVE_EXTRAS', response.data.save_extras)
          }
          commit('SET_ATTR', { attr: 'plan_selection_header', value: response.data.plan_selection_header })
          commit('SET_ATTR', { attr: 'free_plan_selection', value: response.data.free_plan_selection })
          commit('SET_ATTR', { attr: 'details', value: response.data.details })
          commit('SET_ATTR', { attr: 'hasSchedule', value: response.data.has_schedule })
          dispatch('setPrices', response.data.prices, { root: true })
          dispatch('reservation/setRequestedCleaning', response.data.suggested_cleaning, { root: true })

          //commit('setPropertyPicked', true)
          //commit('setIsBooking', true)
          resolve()
        }, (errors) => {
          console.error(errors)
          reject(errors)
        })
        .catch(errors => {
          console.error(errors)
//          commit('clearClient')
  //        commit('clearProperty')
          reject(errors)
        })
    })
  },
  loadProperty({ commit, getters, dispatch }, locationId) {
    return new Promise((resolve, reject) => {
      api
        .getProperty(locationId, 'booking')
        .then(response => {
          commit('SET_ATTR', { attr: 'location_id', value: response.data.id })

          commit('SET_ATTR', { attr: 'bathrooms', value: response.data.bathrooms })
          commit('SET_ATTR', { attr: 'bedrooms', value: response.data.bedrooms })
          commit('SET_ATTR', { attr: 'square_feet', value: response.data.square_feet })
          commit('SET_ATTR', { attr: 'hasFreeParking', value: response.data.has_free_parking })
          commit('SET_ATTR', { attr: 'hasPet', value: response.data.has_pet })
          commit('SET_ATTR', { attr: 'hweEnabled', value: response.data.hwe_enabled })
          commit('SET_ATTR', { attr: 'askParking', value: response.data.askParking })

          commit('SET_ATTR', { attr: 'address', value: response.data.management.property.address })
          commit('SET_ATTR', { attr: 'address2', value: response.data.management.property.address2 })
          commit('SET_ATTR', { attr: 'city', value: response.data.city })
          commit('SET_ATTR', { attr: 'state', value: response.data.state })
          commit('SET_ATTR', { attr: 'zip_code', value: response.data.zip_code })
          commit('SET_ATTR', { attr: 'has_credit_card', value: response.data.has_credit_card })
          resolve()
        }, (errors) => {
          reject(errors)
        })
        .catch(errors => {
          console.error(errors)
          reject(errors)
        })
    })
  },
  remember({ commit, getters }) {
    const s = initialState()
    Object.keys(s).forEach(key => {
      if ( (key != 'note') && (key != 'note_id') ) {
        saveToStore(key, getters[key])
      }
    })
  },
  resume({ commit, dispatch, getters }) {
    commit('SET_ATTR', { attr: 'location_id', value: loadFromStore('location_id') })
    commit('SET_ATTR', { attr: 'saveExtras', value: loadFromStore('saveExtras') })
    commit('SET_EXTRAS', loadFromStore('extras'))
    if (getters.location_id) {
      dispatch('pickLocation', getters.location_id).then(() => {
        if (getters.isLocationIncomplete) {
          commit('SET_ATTR', { attr: 'address', value: loadFromStore('address') })
          commit('SET_ATTR', { attr: 'address2', value: loadFromStore('address2') })
          commit('SET_ATTR', { attr: 'city', value: loadFromStore('city') })
          commit('SET_ATTR', { attr: 'state', value: loadFromStore('state') })
          commit('SET_ATTR', { attr: 'zip_code', value: loadFromStore('zip_code') })
        }
      }, (errors) => {
        console.error(errors)
      })
      return
    }

    commit('SET_ATTR', { attr: 'teamRequired', value: loadFromStore('teamRequired') })
    commit('SET_ATTR', { attr: 'plan_selection_header', value: loadFromStore('plan_selection_header') })
    commit('SET_ATTR', { attr: 'details', value: loadFromStore('details') })
    commit('SET_ATTR', { attr: 'bedrooms', value: loadFromStore('bedrooms') * 1 })
    commit('SET_ATTR', { attr: 'bathrooms', value: loadFromStore('bathrooms') * 1})
    commit('SET_ATTR', { attr: 'square_feet', value: loadFromStore('square_feet') * 1 })
    commit('SET_ATTR', { attr: 'hasFreeParking', value: loadFromStore('hasFreeParking') })
    commit('SET_ATTR', { attr: 'hasPet', value: loadFromStore('hasPet') })
    commit('SET_ATTR', { attr: 'askParking', value: loadFromStore('askParking') })
    commit('SET_ATTR', { attr: 'address', value: loadFromStore('address') })
    commit('SET_ATTR', { attr: 'address2', value: loadFromStore('address2') })
    commit('SET_ATTR', { attr: 'city', value: loadFromStore('city') })
    commit('SET_ATTR', { attr: 'zip_code', value: loadFromStore('zip_code') })
    commit('SET_ATTR', { attr: 'state', value: loadFromStore('state') })
  }
}
