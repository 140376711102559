<template>
  <div>
    <div v-if="termsAccepted">
      <h6 class="text-center">Terms and conditions accepted.</h6>
      <h5 class="text-center">
        <a @click.prevent="showTermsModal" href="#" class="btn btn-link">See Terms of Service</a>
      </h5>
    </div>
    <div v-else>
      <div class="col-12">
        <h4 class="text-center">
          <label class="checkbox-set">
            I accept the terms and conditions
            <input v-model="termsAccepted" type="checkbox">
            <span class="checkmark"></span>
          </label>
        </h4>
        <h5 class="text-center">
          <a @click.prevent="showTermsModal" href="#" class="btn btn-link">See Terms of Service</a>
        </h5>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  computed: {
    termsAccepted: {
      set(v) {
        if (v === true) {
          this.$store.dispatch('client/setTermsAccepted')
        }
      },
      get() {
        return this.$store.getters['client/termsAccepted']
      }
    }
  },
  methods: mapActions(['showTermsModal'])
}
</script>
