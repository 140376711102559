<script>
import api from '@/clients/api'
import CardModal from '@/modals/card_modal.vue'
import {
  instance as StripeInstance
} from 'vue-stripe-elements-plus'
export default {
  extends: CardModal,
  methods: {
    submit() {
      this.$store.commit('START_INDICATOR')
      StripeInstance.confirmCardPayment(this.token, {
        setup_future_usage: 'off_session',
        payment_method: {
          card: this.$refs.stripeCard.$refs.element._element,
        }
      }).then((result) => {
        this.$store.commit('STOP_INDICATOR')
        if (result.error) {
          this.$flasher.error(result.error.message)
        } else {
          this.$flasher.success('Thank you')
          this.$emit('updated')
        }
      })
    }
  }
}
</script>
