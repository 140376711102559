<template>
  <div :class="css">
    <spinner :show="loading" :use-modal="false" :message="spinnerMessage"></spinner>
    <form class="notes">
      <div class="row notes mx-auto">
        <entry-note v-if="note && !loading"
          :note="note"
          :booking="true"
          :location-id="location_id"
          :alarm-code="note.alarm_code"
          :gate-code="note.gate_code"
          :lockbox="note.lockbox"
          :invalid="entryNoteInvalid"
          :required="requireParkingInstructions"
          note-key="instructions"
          class="col-md-6 offset-md-3 col-sm-12 text-center mb-3"></entry-note>
        <div class="note col-sm-6 text-center mb-3">
          <note v-if="note && !loading" :note="note" :booking="true" note-key="bedrooms"></note>
        </div>
        <div class="note col-sm-6 text-center mb-3">
          <note v-if="note && !loading" :note="note" :booking="true" note-key="bathrooms"></note>
        </div>
        <div class="note col-sm-6 text-center mb-3">
          <note v-if="note && !loading" :note="note" :booking="true" note-key="kitchen"></note>
        </div>
        <div class="note col-sm-6 text-center mb-3">
          <note v-if="note && !loading" :note="note" :booking="true" note-key="special"></note>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import Spinner from '../../shared/spinner.vue'
import EntryNote from './entry_note.vue'
import Note from './note2.vue'
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
export default {
  props: {
    css: {
      type: String,
      default: 'additional-notes-sec'
    },
    note: {
      type: Object,
      required: true
    },
    errors: {
      type: Object
    }
  },
  components: { EntryNote, Note, Spinner },
  data() {
    return {
      rawEditedNote: null,
      loading: false,
      spinnerMessage: 'Loading'
    }
  },
  methods: {
    saveNote(params) {
      var noteParams = { note: params, booking: 1 }
      this.loading = true
      this.spinnerMessage = 'Saving'
      if (this.$store.getters['property/note_id'] == null) {
        this.$store.dispatch('property/ensureNote', noteParams).then(() => {
          this.$store.dispatch('property/saveNote', noteParams).then(() => {
            this.editedNote = null
            this.loading = false
          })
        })
      } else {
        this.$store.dispatch('property/saveNote', noteParams).then(() => {
          this.editedNote = null
          this.loading = false
        })
      }
    },
    loadNote(newNote) {
      this.$store.commit('property/SET_NOTE', newNote)
      this.editedNote = null
    },
    setNoteEdited(noteKey) {
      this.editedNote = noteKey
    },
    ...mapActions({
      setReservationAttr: 'reservation/setAttr'
    }),
  },
  computed: {
    ...mapGetters('property', ['location_id', 'requireParkingInstructions']),
    editedNote: {
      set(v) {
        this.rawEditedNote = v
      },
      get() {
        if (this.entryNoteInvalid) {
          return 'instructions'
        }
        return this.rawEditedNote
      }
    },
    entryNoteInvalid() {
      return this.errors.entryNote && (this.errors.entryNote.length > 0)
    },
    someone_home: {
      get() {
        return this.$store.getters['reservation/someone_home']
      },
      set(v) {
        this.setReservationAttr({ attr: 'someone_home', value: v })
      }
    }
  },
}
</script>
