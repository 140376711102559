<template>
  <notes v-if="note" :note="note" :css="css" :errors="errors"></notes>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Notes from '@/properties/shared/notes_for_booking.vue'
export default {
  components: { Notes },
  props: {
    css: {
      type: String,
      default: ''
    },
    errors: {
      type: Object
    }
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      note: 'property/note'
    })
  }
}
</script>
