<template>
  <div id="content">
    <topheader></topheader>
    <div class="container">
      <p class="text-center mt-5">
        <i class="fal fa-file-invoice-dollar fa-2x text-center"></i>
      </p>
      <h2 class="main-head text-center pb-2 mt-0">Invoices</h2>
      <div class="box-wrapper">
        <h2 class="font-weight-light" v-if="invoices.length == 0">No invoices</h2>
        <b-table responsive striped hover :items="invoices" :fields="fields" thead-class="text-center" tbody-tr-class="text-center">
          <template v-slot:cell(url)="row">
            <button v-if="row.item.unpaid" @click="pay(row.item)" class="mr-2 btn-default btn-mini btn-blue">Pay</button>
            <a v-else target="_blank" :href="row.item.url"  class="mr-2 btn-default btn-mini btn-green">
              Invoice
            </a>
          </template>
        </b-table>
      </div>
    </div>
    <payment-modal :show="showCardModal" @close="showCardModal=false" :token="token" @updated="onPaid"></payment-modal>
  </div>
</template>
<script>
import api from '@/clients/api'
import PaymentModal from '@/modals/payment_modal.vue'
export default {
  components: { PaymentModal },
  data() {
    return {
      showCardModal: false,
      token: null,
      fields: [
        'id',
        'address',
        'amount',
        { key: 'identifier', label: 'Invoice Number' },
        'issued',
        'due',
        { key: 'paid_status', label: 'Status' },
        { key: 'url', label: ''}
      ],
      invoices: []
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'white-theme'
    this.$store.commit('STOP_INDICATOR')
    this.getInvoices()
  },
  methods: {
    getInvoices() {
      api.getInvoices().then(response => {
        this.invoices = response.data.invoices
      })
    },
    pay(invoice) {
      api.payInvoice(invoice.id).then(response => {
        if (response.data.pay_url) {
          window.open(response.data.pay_url, '_blank')
        } else {
          if (response.data.token) {
            this.token = response.data.token
            this.showCardModal =  true
          } else {
            this.getInvoices()
          }
        }
      })
    },
    onPaid() {
      this.showCardModal = false
      this.getInvoices()
    }
  }
}
</script>
