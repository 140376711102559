<template>
  <div>
    <div class="modal-blur"></div>
    <div id="content" class="z-1050">
      <topheader></topheader>
      <div class="page-wrapper">
        <div class="container">
          <no-card-warning :no-card="clientHasNoCard"></no-card-warning>
        </div>
      </div>
      <carousel-3d ref="mycarousel" @before-slide-change="onBeforePropertyChange" v-if="showCarousel" :controls-visible="controlsVisible" :border="0" :width="640" :height="300" :space="400">
        <slide v-for="(property, index) in properties" :index="index" :key="property.id">
          <template slot-scope="{ index, isCurrent, leftIndex, rightIndex }">
            <figure>
              <img :data-index="index" :class="{ current: isCurrent, onLeft: (leftIndex >= 0), onRight: (rightIndex >= 0) }" :src="property.big_map_url" />
              <figcaption>
                <p><i class="fas fa-map-pin"></i> &nbsp {{property.address_line1}} ({{property.rooms}}) <strong>{{property.city}}</strong></p>
              </figcaption>
            </figure>
          </template>
        </slide>
      </carousel-3d>
      <div class="page-wrapper">
        <div class="container white-text">
          <h5 class="font-weight-light">{{timeBasedWelcome}}, {{first_name}}</h5>
          <h1 class="font-mid-bold mb-4 mb-0">Where are we cleaning?</h1>
          <div class="row property-dropdown">
            <div class="col-12">
              <div class="dropdown our-location-in">
                <button @click="showMenu = !showMenu" type="button" class="location-btn">Add New Property</button>
                <div v-show="showMenu"  class="location-dropdown">
                  <a @click.prevent="addNewProperty" class="dropdown-item" href="#">Add New Property</a>
                  <a @click.prevent="toManageProperties" class="dropdown-item bottom-line" href="#">Manage Properties</a>
                </div>
              </div>
            </div>
          </div>
          <div class="property-list-container">
            <property-button @propertyIdxHover="propertyHover" @picked="pickLocation" v-for="(property, index) in properties" :property="property" :propertyIndex="index" :carouselIndex="currentIndex" :key="index">
            </property-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import no_card_warning_mixin from '@/shared/no_card_warning_mixin.js'
import Vue from "vue"
import {
  mapActions,
  mapGetters
} from "vuex"
import PropertyButton from "../booking/property_button.vue"
import {
  Carousel3d,
  Slide
} from "vue-carousel-3d"

export default {
  data() {
      return {
        currentIndex: 0,
        showMenu: false
      }
    },
    components: {
      PropertyButton,
      Carousel3d,
      Slide
    },
    mixins: [ no_card_warning_mixin ],
    computed: {
      ...mapGetters({
        client_id: "client/client_id",
        phones: "client/phones",
        has_invalid_phone: "client/has_invalid_phone",
        rating_required: "client/rating_required",
        first_name: "client/first_name",
        properties: "client/properties"
      }),
      timeBasedWelcome() {
        const hours = new Date().getHours()
        if (hours > 5) {
          if (hours <= 12) {
            return "Good Morning"
          } else {
            if (hours < 19) {
              return "Good Afternoon"
            }
          }
        }
        return "Good Evening"
      },
      showCarousel() {
        if (this.properties.length) {
          return true
        } else {
          return false
        }
      },
      controlsVisible() {
        if (this.properties.length <= 1) {
          return false
        } else {
          return true
        }
      }
    },
    mounted() {
      document.getElementsByTagName("body")[0].className = "booking-bg"
      this.$store.dispatch('resetBooking', { dbg: 'propertySelection' }).then(() => {
        if ( (this.phones == null) || (this.phones.length == 0) || (this.has_invalid_phone) ) {
          this.$store.dispatch('client/toAddPhone')
          this.$flasher.error('You need to add your phone before booking')
          return
        }
        if (this.rating_required) {
          this.$flasher.error('You need to rate the cleaning')
          this.$store.dispatch('client/toRating')
          return
        }
        if (this.$store.getters['client/isDisabled']) {
          this.$flasher.error('You need to add a valid payment method to be able to book')
          this.$store.dispatch('toBilling')
          return
        }
        this.$store.dispatch('client/getProperties')
      })
    },
    methods: {
      ...mapActions({
        locationPicked: "property/pickLocation",
        addNewProperty: "property/addNewProperty",
        toManageProperties: "property/toManageProperties",
        resetBooking: "resetBooking"
      }),
      pickLocation(locationId) {
        this.locationPicked(locationId).then(() => {
          this.$store.dispatch("toServiceSelection")
        }, error => {
          this.$store.dispatch('property/toFixProperty', locationId)
          this.$flasher.error(error)
        })
      },
      initMap() {
        var map = new google.maps.Map(document.getElementById("map"), {
          zoom: this.mapZoomLevel,
          center: {
            lat: 33.9842188,
            lng: -118.4652713
          },
          mapTypeControl: false,
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          gestureHandling: "cooperative"
        })

        const locations = []
        var bounds = new google.maps.LatLngBounds()
        var center = new google.maps.LatLng(33.9842188, -118.4652713)
        bounds.extend(center)

        this.properties.forEach(location => {
          var pos = new google.maps.LatLng(location.lat, location.lng)
          bounds.extend(pos)
          locations.push({
            position: pos,
            info: location.full_address
          })
        })

        map.fitBounds(bounds)

        var infowindow

        locations.forEach(function(location) {
          var marker = new google.maps.Marker({
            position: location.coords,
            icon: "~images/map-pointer.png",
            map: map
          })

          marker.addListener("click", function() {
            if (infowindow) {
              infowindow.close()
            }
            const contentString =
              '<div id="map-content">' +
              '<div id="siteNotice">' +
              "</div>" +
              '<h1 id="firstHeading" class="firstHeading">' +
              location.info +
              "</h1>" +
              "</div>"

            infowindow = new google.maps.InfoWindow({
              content: contentString
            })
            infowindow.open(map, marker)
          })
        })
      },
      onBeforePropertyChange(index) {
        this.currentIndex = index
      },
      propertyHover(val) {
        this.$refs.mycarousel.goSlide(val)
      }
    }
}
</script>
