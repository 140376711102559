<template>
  <div class="row">
    <div class="col-md-8 mt-4 mx-auto">
      <div class="py-4" :class="css" @click="pickPlan">
        <div v-if="isActive" class="check-circul"><i class="fas fa-check"></i></div>
        <div>
          <div class="plan-price"><i class="fas fa-dollar-sign"></i>{{price}}</div>
          <h3 class="plan-head" :class="planCss">{{ planTitle }}</h3>
        </div>
        <p v-html="description" class="plan-description"></p>
        <div v-html="smallDescription" class="small-text"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      plan: 'standard'
    }
  },
  computed: {
    ...mapGetters([ 'prices' ]),
    ...mapGetters('reservation', ['requestedCleaning']),
    ...mapGetters({ canPickPlan: 'property/canPickPlan' }),
    planCss() {
      return this.plan
    },
    planTitle() {
      if (this.$i18n) {
        return this.$t(this.plan)
      }
    },
    css() {
      var css = ['plan-wrapper-simple']
      if (this.isActive) {
        css.push('active')
        css.push('plan-wrapper')
      }
      return css.join(' ')
    },
    isActive() {
      return this.requestedCleaning == this.plan
    },
    isStandard() {
      return this.plan == 'standard'
    },
    description() {
      if (this.isStandard) {
        return 'Get your home ready with our deeper cleaning'
      }
      return 'Enjoy your lower basic cleaning rate'
    },
    smallDescription() {
      if (this.isStandard) {
        return 'First time customers<br>Ideal for move in/out cleaning'
      }
      return 'Maintain a clean home with our lower rate after your initial cleaning<br>No contracts or commitments cancel any time'
    },
    price() {
      if (this.prices) {
        return this.prices[this.plan]
      }
    }
  },
  methods: {
    pickPlan() {
      if (this.canPickPlan) {
        this.$store.commit('reservation/SET_REQUESTED_CLEANING', this.plan)
      }
    }
  }
}
</script>
