<template>
  <div>
    <span>Seats</span>
    <div class="selector__counter counter">
      <button class="counter__btn counter__btn--increment" @click="decrease">
        <i class="fa fa-minus"></i>
      </button>
      <input class="selector__control form__control"
        type="text"
        aria-label="seats"
        :value="seats()"
      />
      <button class="counter__btn counter__btn--decrement" @click="increase">
        <i class="fa fa-plus"></i>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    areaKey: {
      type: String,
      required: true
    },
    areaServiceKey: {
      type: String,
    },
    pickedServices: {
      type: Object,
      required: true
    },
    priceKey: {
      type: String,
      required: true
    },
    serviceKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ourSeats: null
    }
  },
  mounted() {
    this.ourSeats = this.seats()
  },
  methods: {
    decrease() {
      if (this.ourSeats > 3) {
        this.ourSeats -= 1
        this.$emit('update', this.updateParam)
      }
    },
    increase() {
      this.ourSeats += 1
      this.$emit('update', this.updateParam)
    },
    seats() {
      if (this.pickedServices[this.serviceKey]) {
        if (this.pickedServices[this.serviceKey][this.areaKey]) {
          if (this.pickedServices[this.serviceKey][this.areaKey].seats) {
            return this.pickedServices[this.serviceKey][this.areaKey].seats
          }
        }
      }
      return 3
    }
  },
  computed: {
    domId() {
      return this.serviceKey + '-' + this.areaKey + '-seats'
    },
    updateParam() {
      return { service: this.serviceKey, area: this.areaKey, priceKey: this.priceKey, seats: this.ourSeats }
    },
  }
}
</script>
