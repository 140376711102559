<template>
  <div id="content">
    <topheader></topheader>
    <mobile-summary-widget
        :info="summaryInfo"
        :promo-code="promoCode"
        @save:promo_code="$emit('save:promo_code')"
        @remove:promo_code="$emit('remove:promo_code')"
        @update:promo_code="$emit('update:promo_code', $event)"
    />
    <div class="container-fluid px-5">
      <div class="row d-flex justify-content-center">
        <div class="content-wrapper booking-main-content mx-3 my-5">
          <step-indicator />
          <div class="form-wrapper">
            <div @click="toPrevStep" class="prev-button">
              <i class="fas fa-angle-left fa-3x"></i>
            </div>
            <h2 class="form-head mb-4 pt-sm-4">Add Extras</h2>
            <extras-for-booking
              :allowed-extra-ids="allowedCustomExtraIds"
              :can-remove-extras="true"
              :save-extras="save_extras"
              :saved-extras="extras"
              :property-id="propertyId"
              @save="onSubmit"
              @update="updateExtras"
              @update:picked-extras="updatePickedExtras"
              @update:save-extras="save_extras = $event"
              ></extras-for-booking>
          </div>
        </div>
        <div class="content-wrapper booking-secondary-content d-none d-md-block">
            <desktop-summary-widget
                :info="summaryInfo"
                :promo-code="promoCode"
                @save:promo_code="$emit('save:promo_code')"
                @update:promo_code="$emit('update:promo_code', $event)"
                @remove:promo_code="$emit('remove:promo_code')"
            />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

import AskTerms from '../ask_terms.vue'
import StandardCleaningCard from '../booking/standard_cleaning_card.vue'
import UpkeepCleaningCard from '../booking/upkeep_cleaning_card.vue'
import ExtrasForBooking from '../booking/extras_for_booking.vue'

export default {
  components: { AskTerms, StandardCleaningCard, UpkeepCleaningCard, ExtrasForBooking },
  computed: {
    header() {
      return 'Header Text'
    },
    save_extras: {
      get() {
        return this.save_extras_in_store
      },
      set(v) {
        this.$store.commit('property/SET_SAVE_EXTRAS', v)
      }
    },
    plans() {
      return Window.plans
    },
    ...mapGetters(['summaryInfo']),
    ...mapGetters({
      promoCode: 'reservation/promo_code',
      extras: 'property/extras',
      propertyId: 'property/location_id',
      allowedCustomExtraIds: 'client/allowedCustomExtraIds',
      save_extras_in_store: 'property/saveExtras'
    })
  },
  methods: {
    onSubmit() {
      this.$store.dispatch('toDetails')
    },
    toPrevStep() {
      this.$store.dispatch('toPlanSelection', null, { root: true })
    },
    updateExtras(newExtras) {
      this.$store.commit('property/SET_EXTRAS', newExtras)
    },
    updatePickedExtras(pickedExtra) {
      this.$store.commit('reservation/UPDATE_PICKED_EXTRAS', pickedExtra)
    }
  },
  mounted() {
    this.$store.commit('STOP_INDICATOR')
  }
}
</script>
