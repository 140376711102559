<template>
  <div id="content">
    <topheader></topheader>
    <div class="container-fluid px-5">
      <div class="row d-flex justify-content-center">
        <div class="content-wrapper mw-670">
          <div class="form-wrapper">
            <h2 class="form-head mb-4">Update your Property</h2>
            <my-form :readonly="readonly" :start-in-edit-mode="true">
              <template v-slot:default="{ invalid, valid, formIsReadonly }">
                <div class="row">
                  <div class="col-sm-6"><address-field v-model="address" /></div>
                  <div class="col-sm-6"><city-field v-model="city" /></div>
                  <div class="col-sm-6"><state-dropdown v-model="state" /></div>
                  <div class="col-sm-6"><zip-code-field v-model="zip_code" /></div>
                </div>
                <h2 class="form-head mt-4">And about your home!</h2>
                <property-slider :bedrooms="bedrooms" :bathrooms="bathrooms" :square-feet="square_feet" @update="sliderUpdate"></property-slider>
              </template>
              <template v-slot:edit-buttons="{ invalid, validated }">
                <b-button :disabled="invalid && validated" type="submit" variant="primary main-btn mt-4">Update</b-button>
              </template>
            </my-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapMutations,
  mapGetters
} from 'vuex'

import AddressField from '@/shared/fields/address.vue'
import CityField from '@/shared/fields/city.vue'
import StateDropdown from '@/shared/fields/state.vue'
import ZipCodeField from '@/shared/fields/zip.vue'
import PropertySlider from '@/properties/shared/slider.vue'

import locking_mixin from '@/shared/locking_mixin'

export default {
  mixins: [ locking_mixin ],
  components: { PropertySlider, AddressField, CityField, ZipCodeField, StateDropdown },
  computed: {
    address: {
      get() {
        return this.$store.getters['property/address']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'address', value: v })
      }
    },
    state: {
      get() {
        return this.$store.getters['property/state']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'state', value: v })
      }
    },
    city: {
      get() {
        return this.$store.getters['property/city']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'city', value: v })
      }
    },
    zip_code: {
      get() {
        return this.$store.getters['property/zip_code']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'zip_code', value: v })
      }
    },
    bedrooms: {
      get() {
        return this.$store.getters['property/bedrooms'] * 1
      },
      set(v) {
        this.setPropertyAttr({ attr: 'bedrooms', value: v })
      }
    },
    bathrooms: {
      get() {
        return this.$store.getters['property/bathrooms'] * 1
      },
      set(v) {
        this.setPropertyAttr({ attr: 'bathrooms', value: v })
      }
    },
    square_feet: {
      get() {
        return this.$store.getters['property/square_feet'] * 1
      },
      set(v) {
        this.setPropertyAttr({ attr: 'square_feet', value: v })
      }
    },
    locationId() {
      return this.$route.params.id * 1
    },
    ...mapGetters('client', ['isAuthenticated'])
  },
  methods: {
    sliderUpdate(attrs) {
      this.bathrooms = attrs.bathrooms
      this.bedrooms = attrs.bedrooms,
      this.square_feet = attrs.square_feet
    },
    submit() {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.updateProperty().then(() => {
          this.locationPicked(this.locationId).then(() => {
            this.$store.dispatch("toServiceSelection")
            resolve()
            this.loading = false
          })
        }, (errors) => {
          this.loading = false
          reject(error)
        })
      })
    },
    ...mapActions({
      locationPicked: "property/pickLocation",
      updateProperty: 'property/update',
      setClientAttr: 'client/setAttr',
      setPropertyAttr: 'property/setAttr'
    })
  },
  mounted() {
    this.$store.dispatch('property/loadProperty', this.locationId).then(() => {
      document.getElementsByTagName('body')[0].className = 'booking-bg'
      this.$store.commit('STOP_INDICATOR')
    })
  }
}
</script>
