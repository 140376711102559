<template>
  <div id="content">
    <div class="page-wrapper">
      <div class="col-md-12 box-wrapper">
        <dispute-form :client-id="clientId" :job-id="jobId" @update="disputeCreated" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import api from '@/clients/api'
import DisputeForm from '@/disputes/new_dispute_form.vue';

export default {
  components: {
    DisputeForm
  },
  computed: {
    clientId() {
      return this.$store.getters['client/client_id']
    },
    jobId() {
      return this.$route.params.job_id * 1
    }
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'white-theme'
  },
  methods: {
    disputeCreated() {
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>
