<template>
  <div id="content">
    <topheader></topheader>
    <div class="container-fluid px-5">
      <div class="row d-flex justify-content-center">
        <div class="content-wrapper mw-670">
          <div v-if="!loading && (pay_url == null)" class="form-wrapper">
            <div v-if="numJobs > 0"class="alert alert-warning text-center" role="alert">
              You have {{numJobs}} job(s) that you need to rate <button @click="postponeRating" class="btn btn-light">Later</button>
            </div>

            <div class="text-center">
              <img src="@images/avatar_placeholder.jpg" class="user-avatar" width="150px">
              <h4 class="text-center font-weight-light">{{job.cleaner}}</h4>
            </div>

            <p class="text-center font-weight-light">
              <strong><i class="fal fa-map-pin"></i></strong> {{job.address}} <br>
              <strong><i class="fal fa-clock"></i></strong> {{job.start}} <br>
            </p>

            <rate-form
              @update="onDoneRating"
              :short-start-dispute-url="job.short_start_dispute_url"
              :rating="job.rating"
              :feedback="job.feedback"
              :tip-cents="job.tip_cents"
              :job-ids="job.ids"
              :main-job-id="job.main_job_id"
              :total-cents="job.total_cents"
              :cleaner="job.cleaner" />

          </div>
          <div v-if="!loading && pay_url" class="form-wrapper">
            <h2 class="form-head mb-4">Please click the button below to pay the tip now</h2>
            <h6>You can also pay the tip from your invoices page</h6>
            <button @click="pay" class="btn btn-primary">Pay ${{this.tip}} Tip Now</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/clients/api'
import RateForm from '@/shared/rate_form.vue'
export default {
  components: {
    RateForm
  },
  data() {
    return {
      loading: true,
      job: {},
      numJobs: 0,
      pay_url: null,
    }
  },
  methods: {
    onDoneRating() {
      this.loading = false
      this.$store.dispatch('toPropertySelection')
    },
    postponeRating() {
      this.$store.dispatch('client/postponeRating')
    },
    pay() {
      this.$store.dispatch('toPropertySelection')
      window.open(this.pay_url, '_blank')
    },
  },
  mounted() {
    api.getLastCleaningJob().then((response) => {
      this.job = response.data.job
      this.numJobs = response.data.numJobs
      document.getElementsByTagName('body')[0].className = 'booking-bg'
      this.$store.commit('STOP_INDICATOR')
      this.loading = false
    })
  }
}
</script>
