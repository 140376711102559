<template>
  <div id="content">
    <topheader></topheader>
    <mobile-summary-widget :info="summaryInfo" />
    <div class="container-fluid px-5">
      <div class="row d-flex justify-content-center">
        <div class="content-wrapper booking-main-content mx-3 my-5">
          <step-indicator />
          <div class="form-wrapper">
            <div v-if="showPrevArrow" @click="toPrevStep" class="prev-button">
              <i class="fas fa-angle-left fa-3x"></i>
            </div>
            <h2 class="form-head mb-4 pt-sm-4">What can we clean for you?</h2>
            <h3 class="sub-head text-center">{{ header }}</h3>
            <div class="my-5">
              <service
                v-for="service in services"
                :key="service.id"
                :service="service"
                :picked-services="pickedServices"
                @add="addService"
                @update-seats="updateSeats"
                @update-sectional="updateSectional"
                @update-dimension="updateServiceDimension"
                @remove="removeService"
                @remove-area="removeArea"
              />
            </div>
            <additional-info
              :show-portable-info="true"
              :form-centered="true"
              @update="onUpdateInfo"
              class="display--mobile d-none"
            />
            <ask-terms />
            <b-button @click="submit" :disabled="loading" variant="primary" class="main-btn mt-5">Next</b-button>
          </div>
        </div>
        <div
          class="content-wrapper booking-secondary-content d-none d-md-block"
        >
          <desktop-summary-widget :info="summaryInfo" />
          <additional-info
            :show-portable-info="true"
            @update="onUpdateInfo"
            class="breakdown-summary-wrapper mt-2"  />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import AskTerms from '@/clients/ask_terms.vue';
import Service from '@/clients/booking/hwe/service.vue';
import AdditionalInfo from '@/clients/booking/shared/additional_info.vue'

export default {
  components: { AskTerms, Service, AdditionalInfo },
  data() {
    return {
      header: '',
      isOpen: false,
    };
  },
  computed: {
    showPrevArrow() {
      return this.newProperty;
    },
    ...mapGetters(['summaryInfo', 'loading']),
    ...mapGetters({
      pickedAnyService: 'hwe/pickedAnyService',
      services: 'hwe/services',
      pickedServices: 'hwe/pickedServices',
    })
  },
  methods: {
    addService(service) {
      this.$store.commit('hwe/ADD_SERVICE', service)
    },
    removeService(service) {
      this.$store.commit('hwe/REMOVE_SERVICE', service)
    },
    updateServiceDimension(service) {
      this.$store.commit('hwe/UPDATE_SERVICE_DIMENSION', service)
    },
    updateSeats(service) {
      this.$store.commit('hwe/UPDATE_SEATS', service)
    },
    updateSectional(service) {
      this.$store.commit('hwe/UPDATE_SECTIONAL', service)
    },
    onUpdateInfo(info) {
      this.$store.commit('hwe/UPDATE_INFO', info)
    },
    submit() {
      if (this.pickedAnyService) {
        this.$store.dispatch('toDetails').then(() => {
        })
      } else {
        this.$flasher.error('You need to pick a service')
      }
    },
    removeArea(params) {
      this.$store.commit('hwe/REMOVE_SERVICE_AREA', params)
    },
  },
  mounted() {
    this.$store.dispatch('hwe/getServices').then(() => {
      this.$store.commit('STOP_INDICATOR')
    })
  },
};
</script>
