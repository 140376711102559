<template>
  <div class="row" v-bind="$attrs">
    <div class="col-sm offset-sm-2">
      <ValidationProvider vid="petsVid" name="pets">
        <b-form-group label-cols-sm="7" content-cols-sm="4" label="Do you have pets in this property?" slot-scope="{ validated }">
          <b-form-checkbox-group name="pets">
            <div class="pt-2 custom-control custom-control-inline custom-checkbox">
              <input type="checkbox" :checked="hasPet" @input="onChangeYes" name="pets_yes" class="custom-control-input" value="hasPet" id="hasPet" :class="statusClass(validated)">
                <label class="custom-control-label" for="hasPet">Yes</label>
            </div>
            <div class="pt-2 custom-control custom-control-inline custom-checkbox">
              <input type="checkbox" :checked="noPet" @input="onChangeNo" name="pets_no" class="custom-control-input" value="noPet" id="noPet" :class="statusClass(validated)">
                <label class="custom-control-label" for="noPet">No</label>
            </div>
          </b-form-checkbox-group>
        </b-form-group>
      </ValidationProvider>
    </div>
    <pets-offer-modal @update="$emit('update:wants-sitter-paws', $event)"/>
  </div>
</template>
<script>
import PetsOfferModal from '@/clients/booking/shared/pets_offer_modal.vue'
export default {
  props: {
    value: {
      type: null
    },
    wantsSitter4Paws: {
      type: Boolean,
    }
  },
  components: { PetsOfferModal },
  computed: {
    hasPet() {
      return this.value === true
    },
    noPet() {
      return this.value === false
    }
  },
  methods: {
    onChangeYes(e) {
      let v = e.target.checked === true ? true : null
      if ((this.wantsSitter4Paws != true) && (v == true)) {
        this.$bvModal.show('pets-offer-modal')
      }
      this.$emit('input', v)
    },
    onChangeNo(e) {
      let v = e.target.checked === true ? false : null
      this.$emit('input', v)
    },
    statusClass(validated) {
      if (validated) {
        return this.value != null ? 'is-valid' : 'is-invalid'
      }
      return ''
    }
  }
}
</script>
