<template>
  <div v-if="noCard" class="row justify-content-center">
    <div class="col alert alert-danger text-center" role="alert">You do not have an active card for each of your properties, please update your <router-link v-if="showLink" to="/billing">payment settings</router-link><span v-else>payment settings</span></div>
  </div>
</template>
<script>
export default {
  props: {
    noCard: {
      type: Boolean,
      required: true
    },
    showLink: {
      type: Boolean,
      default: true
    }
  },
}
</script>
