<template>
  <div id="content">
    <topheader></topheader>
    <div class="container main-wrapper">
      <div class="row">
        <div class="col-12">
          <no-card-warning :no-card="clientHasNoCard"></no-card-warning>
          <div class="mb-4">
            <h1 class="main-head text-center">Manage Properties</h1>
          </div>
        </div>
      </div>
      <h2 v-if="properties.length == 0">No properties</h2>
      <div class="row">
        <div class="col-lg-8 col-md-12 offset-lg-2">
          <property-as-list v-for="property in properties" :property="property" @go:visits="goVisits" @go:property="goProperty" @book="bookProperty" :key="property.id" @ask-ical-url="onAskIcalUrl(property.id)"></property-as-list>
        </div>
      </div>
      <div class="btn-set btn-set-blue pt-3 mb-2">
        <router-link :to="{ name: 'new-property' }" class="btn btn-default"><i class="fal fa-plus-circle mr-2"></i>Add New Property</router-link>
      </div>
    </div>
    <pick-date-modal
      :show="showPickDateModal"
      :fix-starts="starts"
      :flexible-ranges="unreserved_ranges"
      @toggle-flexible="showFlexible = $event"
      @request="onRequestBookings"
      :flexible-description="flexibleDescription"
      title="Review Your Booking Dates"
    />
    <ask-ical-url-modal
      :show="showIcalUrlModal"
      :property-ical-url="savedIcalUrl"
      :check-in-hour="savedCheckInHour"
      :check-out-hour="savedCheckOutHour"
      @import="importIcal"
      @close="showIcalUrlModal = false"
    />
  </div>
</template>
<script>
import api from '@/clients/api'
import no_card_warning_mixin from '@/shared/no_card_warning_mixin'
import PropertyAsList from '@/properties/shared/as_list.vue'
import import_ics_mixin from '@/properties/shared/import_ics_mixin'

export default {
  components: { PropertyAsList },
  mixins: [ no_card_warning_mixin, import_ics_mixin ],
  computed: {
    properties() {
      return this.$store.getters['client/properties']
    },
  },
  mounted() {
    this.$store.dispatch('client/getProperties').then(() => {
      document.getElementsByTagName('body')[0].className = 'white-theme'
      this.$store.commit('STOP_INDICATOR')
    })
  },
  methods: {
    bookProperty(propertyId) {
      this.$store.dispatch('property/pickLocation', propertyId).then(() => {
        this.$store.dispatch('toPlanSelection')
      }, error => {
        this.$flasher.error(error)
      })
    },
    goProperty(propertyId) {
      this.$router.push({ path: `/properties/${propertyId}` })
    },
    goVisits(propertyId) {
      this.$router.push({ path: `/properties/${propertyId}/reservations` })
    },
  }
}
</script>
