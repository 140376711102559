<template>
  <base-modal dom-id="doubleBookingModal" :show="show" bodyClass="modal-body white-theme" classes="modal-white-theme" modalSize="" data-backdrop="static">
    <div slot="header" class="modal-header"><h5 class="modal-title">Double Booking</h5></div>
    <div slot="content" v-html="content"></div>
    <div slot="footer">
      <button type="button" class="btn btn-green" data-dismiss="modal" @click="$emit('confirm-double-booking')">Confirm</button>
      <button type="button" class="btn btn-red" @click="closeDoubleBookingModal">Cancel</button>
    </div>
  </base-modal>
</template>
<script>
import BaseModal from '@/modals/base_modal.vue'
import { mapActions } from 'vuex'
export default {
  components: { BaseModal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      required: true
    }
  },
  methods: mapActions(['closeDoubleBookingModal'])
}
</script>
