<template>
  <div class="selector__col">
    <span class="selector__label">{{service.title}}</span>
    <div class="selector__input">
      <b-form-checkbox :id="domId" v-model="checked" class="form-checkbox">
        <label :for="domId" tabindex="1"><span class="hide">{{service.title}}</span></label>
      </b-form-checkbox>
    </div>
    <p class="selector__price-info"><span>{{price}}</span></p>
  </div>
</template>
<script>
import Base from './base.vue'
export default {
  extends: Base,
  computed: {
    checked: {
      get() {
        if (this.pickedServices[this.serviceKey]) {
          if (this.pickedServices[this.serviceKey][this.areaKey]) {
            if (this.pickedServices[this.serviceKey][this.areaKey][this.areaServiceKey]) {
              return this.pickedServices[this.serviceKey][this.areaKey][this.areaServiceKey] > 0
            }
          }
        }
        return false
      },
      set(v) {
        if (v) {
          this.$emit('add', this.changeParam)
        } else {
          this.$emit('remove', this.changeParam)
        }
      }
    },
    domId() {
      return 'cb-' + this.baseDomId
    }
  },
  methods: {
  }
}
</script>
