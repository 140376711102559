<template>
  <span v-if="hasInfo">
    <i :id="slug" class="fal fa-info-circle"></i>
    <b-popover :show.sync="show" :target="slug">
      <template #title>
        <b-button @click="onClose" class="close" aria-label="Close">
        <span class="d-inline-block" aria-hidden="true">&times;</span>
        </b-button>
        {{title}}
      </template>
      <p class="p-2">{{info}}</p>
    </b-popover>
  </span>
</template>

<script>
export default {
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    onClose() {
      this.show = false
    },
  },
  computed: {
    hasInfo() {
      return !!this.service.info
    },
    info() {
      return this.service.info
    },
    title() {
      return this.service.title
    },
    slug() {
      return this.service.slug
    }
  }
}
</script>
