<template>
  <div>
    <h4 class="form-head text-center mt-5 px-md-5 mb-4">You already have an active schedule for this property</h4>
    <p class="text-center px-md-4">Adjust your schedule in the calendar or book a single visit. Contact us if you need help with your bookings: {{companyEmail}} {{companyPhone}}</p>
    <div class="btn-set">
      <a href="/calendar" class="btn btn-default text-capitalize" target="_blank">View Calendar</a>
    </div>
  </div>
</template>
<script>
import company_phone_mixin from '@/shared/company_phone_mixin'
export default {
  mixins:[company_phone_mixin]
}
</script>
