<template>
  <b-form-checkbox :id="domId" v-model="checked" class="form-checkbox">
    <label :for="domId" tabindex="1">Secional?</label>
  </b-form-checkbox>
</template>
<script>
export default {
  props: {
    areaKey: {
      type: String,
      required: true
    },
    pickedServices: {
      type: Object,
      required: true
    },
    priceKey: {
      type: String,
      required: true
    },
    serviceKey: {
      type: String,
      required: true
    }
  },
  data() {
    return {
    }
  },
  methods: {},
  computed: {
    checked: {
      set(v) {
        var info = this.updateParam
        info.sectional = v
        this.$emit('update', info)
      },
      get() {
        if (this.pickedServices[this.serviceKey]) {
          if (this.pickedServices[this.serviceKey][this.areaKey]) {
            if (this.pickedServices[this.serviceKey][this.areaKey].sectional) {
              return this.pickedServices[this.serviceKey][this.areaKey].sectional
            }
          }
        }
        return false
      }
    },
    domId() {
      return this.serviceKey + '-' + this.areaKey + '-sectional'
    },
    updateParam() {
      return { service: this.serviceKey, area: this.areaKey, priceKey: this.priceKey }
    },
  }
}
</script>
