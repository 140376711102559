<template>
  <dl class="expandibles">
    <div class="expandable expandable--selector">
      <dl>
        <dt v-b-toggle="domId" class="expandable__title" :class="isActive ? 'expandable__title--active' : ''">
          <button class="btn rounded-circle btn-activate" :class="isActive ? 'fa-rotate-180' : ''">
            <i class="fas fa-chevron-down"></i>
            <span class="hide">Expand {{title}} services </span>
          </button>
          <span class="h5">{{title}} &nbsp;</span>
          <popover-info :service="service" />
        </dt>
        <b-collapse :id="domId" v-model="isActive">
          <dd class="expandable__body">
            <div class="selector selector--area">
              <div class="selector__row selector__head">
                <div v-for="service in services" :key="service" class="selector__col">
                  <span>{{ service }}</span>
                </div>
              </div>
              <div v-for="(area, areaKey) in areas" :key="areaKey" class="selector__row">
                <div class="position-relative">
                  <area-select v-if="area.askDimension"
                    :area="area"
                    :area-key="areaKey"
                    :service="service"
                    :picked-services="pickedServices"
                    @update-dimension="$emit('update-dimension', $event)"
                    @remove-area="$emit('remove-area', $event)"
                  />
                  <div v-else class="selector__col">
                    <p>
                      {{area.title}}
                      <template v-if="area.priceKey == 'sofa'">
                        <sectional-checkbox
                          :area-key="areaKey"
                          :picked-services="pickedServices"
                          :price-key="area.priceKey"
                          :service-key="service.slug"
                          @update="$emit('update-sectional', $event)"
                        />
                        <seats-input
                          :area-key="areaKey"
                          :picked-services="pickedServices"
                          :price-key="area.priceKey"
                          :service-key="service.slug"
                          @update="$emit('update-seats', $event)"
                        />
                      </template>
                      <template v-if="service.slug == 'upholstery-common'">
                        <button class="btn btn-link" @click="addUpholstery(area)"><i class="fa fa-plus"></i> Add More</button>
                      </template>
                      <span class="selector__price-info" v-if="area.info">({{area.info}})</span>
                    </p>
                    <div class="mobile float-right">
                      <i class="fas fa-chevron-down"></i>
                    </div>
                  </div>
                </div>
                <component v-for="(areaService, areaServiceKey) in servicesPerArea" v-bind:is="selectComponent(area)"
                  :area="area"
                  :area-key="areaKey"
                  :area-service="areaService"
                  :area-service-key="areaServiceKey"
                  :key="areaKey + '-' + areaServiceKey"
                  :service="service"
                  :picked-services="pickedServices"
                  :prices="prices"
                  @update-dimension="$emit('update-dimension', $event)"
                  @add="$emit('add', $event)"
                  @remove-area="$emit('remove-area', $event)"
                  @remove="$emit('remove', $event)"
                />
              </div>
            </div>
          </dd>
          <div v-if="allowAdditional" class="expandable__actions px-3">
            <button class="btn btn-primary main-btn" @click="addAreaRow"><i class="fa fa-plus"></i> Add Additional Cleaning Area</button>
          </div>
          <div class="expandable__footer">
            <p class="my-2 text-center"><i class="fa fa-info-circle"></i> {{info}}</p>
          </div>
        </b-collapse>
      </dl>
    </div>
  </dl>
</template>
<script>
import Vue from 'vue'
import api from '@/clients/api'
import { mapGetters } from 'vuex'
import AreaSelect from './widgets/area.vue'
import QuantityWidget from './widgets/for_quantity.vue'
import CheckboxWidget from './widgets/checkbox.vue'

import PopoverInfo from './popover_info.vue'
import SectionalCheckbox from './sectional_checkbox.vue'
import SeatsInput from './seats_input.vue'

export default {
  components: {
    AreaSelect,
    QuantityWidget,
    CheckboxWidget,
    PopoverInfo,
    SectionalCheckbox,
    SeatsInput
  },
  props: {
    service: {
      type: Object,
      required: true
    },
    pickedServices: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isActive: false,
    }
  },
  computed: {
    areas() {
      return this.serviceDetails.areas
    },
    blankArea() {
      const area = { info: 'length & width in sq.ft', title: 'length & width', askDimension: true, priceKey: 'area', isRemovable: true }
      return area
    },
    services() {
      if (this.servicesPerArea) {
        return ['/'].concat(Object.keys(this.servicesPerArea))
      }
    },
    allowAdditional() {
      return this.service.allowAdditional
    },
    domId() {
      return 'hwe-' + this.service.id
    },
    info() {
      return this.service.info
    },
    prices() {
      return this.serviceDetails.prices
    },
    serviceDetails() {
      return this.$store.getters['hwe/services'][this.service.slug]
    },
    servicesPerArea() {
      return this.serviceDetails.services
    },
    title() {
      return this.service.title
    }
  },
  methods: {
    addAreaRow() {
      const areaIdx = 'area-' + Object.keys(this.areas).length
      this.$store.commit('hwe/SET_SERVICE_AREA', { serviceSlug: this.service.slug, areaSlug: areaIdx, area: this.blankArea })
    },
    addUpholstery(area) {
      const areaIdx = area.priceKey + '-' + Object.keys(this.areas).length
      var blank = { title: area.title, priceKey: area.priceKey, isRemovable: true }
      this.$store.commit('hwe/SET_SERVICE_AREA', { serviceSlug: this.service.slug, areaSlug: areaIdx, area: blank })
    },
    sectional(areaKey) {
      if (this.pickedServices && this.pickedServices[this.service.slug] && this.pickedServices[this.service.slug][areaKey]) {
        return this.pickedServices[this.service.slug][areaKey].sectional
      }
    },
    selectComponent(area) {
      return (area.widget || 'quantity') + '-widget'
    },
  },
  mounted() {
    this.$store.commit('START_INDICATOR')
    this.$store.dispatch('hwe/getHweService', this.service.id).then(() => {
      this.$store.commit('STOP_INDICATOR')
    })
  }
}
</script>
