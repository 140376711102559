<template>
  <div id="content">
    <topheader></topheader>
    <div class="page-wrapper">
      <step-indicator />
    </div>
    <div class="container-fluid px-5">
      <div class="row d-flex justify-content-center">
        <div class="content-wrapper mw-670">
          <div class="form-wrapper">
            <h2 class="form-head mb-4">Tell us about yourself</h2>
            <booking-form v-if="!showServiceSelectionModal">
              <div class="row">
                <div class="col-sm-6"><first-name-field v-model="first_name" /></div>
                <div class="col-sm-6"><last-name-field v-model="last_name" /></div>
                <div class="col-sm-12"><email-field v-model="email" /></div>
                <div class="col-sm-12"><phone-field v-model="phone" description="Text me with my updates, news and offers" /></div>
                <div class="col-sm-12 pt-0">
                  <checkbox-field v-model="text_consent">
                    I agree to receive texts messages at the phone number provided
                  </checkbox-field>
                </div>
                <div class="col-sm-12 pt-0">
                  <checkbox-field v-model="text_offers_consent">
                    I agree to receive recurring updates, news and offers at the phone number provided
                  </checkbox-field>
                </div>
                <div class="col-sm-12 pt-0">
                  <p class="text-tiny">Consent is not a condition of purchase. Reply STOP to unsubscribe.
                    Reply HELP for help. Message frequency varies.
                    Msg & data rates may apply.
                    View our
                    <a href="https://www.wavehousecleaning.com/privacy-policy/" target="_blank">privacy policy</a> and
                    <a href="https://www.wavehousecleaning.com/terms-and-conditions/" target="_blank">terms of service</a>.
                  </p>
                </div>
              </div>

              <h2 class="form-head mt-4">And about your home</h2>

              <div class="row">
                <div v-if="googleAutocompleteLoad" class="col-sm-12">
                  <address-fields @update="onAddressUpdate" :address="addressObject" @noGoogle="loadAddressFallback" />
                </div>
                <div v-else class="col-sm-12">
                  <address-fallback 
                    @update="onAddressUpdate"
                    :address="addressObject" 
                  ></address-fallback>
                </div>
              </div>
              <has-pet-question v-model="hasPet" @update:wants-sitter-paws="onUpdateWantsSitter4Paws" :wants-sitter4-paws="wantsSitter4Paws"></has-pet-question>
              <property-slider @update="sliderUpdate"></property-slider>
              <template v-slot:buttons="{ invalid, validated }">
                <b-button :disabled="invalid && validated" type="submit" variant="primary" class="main-btn mt-4">Get Your Estimate</b-button>
              </template>
            </booking-form>
            <div class="text-center text-xs mt-3">
              <div>Exiting user? <a href="/login">Sign In</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mapActions,
  mapMutations,
  mapGetters
} from 'vuex'

import PropertySlider from '@/properties/shared/slider.vue'
import FirstNameField from '@/shared/fields/first_name.vue'
import LastNameField from '@/shared/fields/last_name.vue'
import EmailField from '@/shared/fields/email.vue'
import AddressFields from '@/shared/address_fields.vue'
import PhoneField from '@/shared/fields/phone.vue'
import CheckboxField from '@/shared/fields/consent_checkbox.vue'
import AddressFallback from '@/shared/address_fallback.vue'
import HasPetQuestion from '@/clients/has_pet_question.vue'

export default {
  components: { PropertySlider, AddressFields, EmailField, FirstNameField, LastNameField, PhoneField, CheckboxField, AddressFallback, HasPetQuestion },
  computed: {
    first_name: {
      get() {
        return this.$store.getters['client/first_name']
      },
      set(v) {
        this.setClientAttr({ attr: 'first_name', value: v })
      }
    },
    last_name: {
      get() {
        return this.$store.getters['client/last_name']
      },
      set(v) {
        this.setClientAttr({ attr: 'last_name', value: v })
      }
    },
    email: {
      get() {
        return this.$store.getters['client/email']
      },
      set(v) {
        this.setClientAttr({ attr: 'email', value: v })
      }
    },
    address: {
      get() {
        return this.$store.getters['property/address']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'address', value: v })
      }
    },
    address2: {
      get() {
        return this.$store.getters['property/address2']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'address2', value: v })
      }
    },
    fullAddress: {
      get() {
        const addr = this.$store.getters['property/fullAddress']
        if (this.ourFullAddress) {
          return this.ourFullAddress
        }
        return addr
      },
      set(v) {
        this.ourFullAddress = v
      }
    },
    state: {
      get() {
        return this.$store.getters['property/state']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'state', value: v })
      }
    },
    city: {
      get() {
        return this.$store.getters['property/city']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'city', value: v })
      }
    },
    zip_code: {
      get() {
        return this.$store.getters['property/zip_code']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'zip_code', value: v })
      }
    },
    phone: {
      get() {
        return this.$store.getters['client/phone']
      },
      set(v) {
        this.setClientAttr({ attr: 'phone', value: v })
      }
    },
    bedrooms: {
      get() {
        return this.$store.getters['property/bedrooms']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'bedrooms', value: v })
      }
    },
    bathrooms: {
      get() {
        return this.$store.getters['property/bathrooms']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'bathrooms', value: v })
      }
    },
    square_feet: {
      get() {
        return this.$store.getters['property/square_feet']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'square_feet', value: v })
      }
    },
    text_consent: {
      get() {
        return this.$store.getters['client/text_consent']
      },
      set(v) {
        this.setProfileAttr({ attr: 'text_consent', value: v === true })
      }
    },
    text_offers_consent: {
      get() {
        return this.$store.getters['client/text_offers_consent']
      },
      set(v) {
        this.setProfileAttr({ attr: 'text_offers_consent', value: v === true })
      }
    },
    hasPet: {
      get() {
        return this.$store.getters['property/hasPet']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'hasPet', value: v })
      }
    },
    ...mapGetters('client', ['isAuthenticated', 'client_id', 'wantsSitter4Paws']),
    ...mapGetters('property', ['addressObject']),
    ...mapGetters(['showServiceSelectionModal']),
  },
  data() {
    return {
      ourFullAddress: null,
      submitStatus: null,
      googleAutocompleteLoad: true
    }
  },
  methods: {
    onAddressUpdate({ field, value }) {
      this[field] = value
    },
    submit() {
      return new Promise((resolve, reject) => {
        if (this.hasPet == null) {
          reject({ errors: [ 'Please answer the question'] })
          return
        }

        if (this.isAuthenticated) {
          this.$store.dispatch('client/update').then(() => {
            this.$store.dispatch('property/check').then(() => {
              this.$store.dispatch('property/create_or_update', 'booking').then(() => {
                this.$store.dispatch('toServiceSelection')
                resolve()
              })
            }, (errors) => {
              reject(errors)
            })
          }, (errors) => {
            reject(errors)
          })
        } else {
          this.$store.dispatch('startBooking')
          this.$store.dispatch('client/signUp').then(() => {
            if (process.env.NODE_ENV == 'production') {
              this.$gtag.event('sign_up', {
                send_to: 'AW-1019391135/Y82ZCKvdqVsQn9mK5gM',
                client_id: this.client_id
              })
            }
            this.$store.dispatch('property/create_or_update').then(() => {
              this.$store.dispatch('toServiceSelection')
              resolve()
            }, (errors) => {
             reject(errors)
            })
          }, (errors) => {
            reject(errors)
          })
        }
      })
    },
    sliderUpdate(attrs) {
      this.bathrooms = attrs.bathrooms
      this.bedrooms = attrs.bedrooms,
      this.square_feet = attrs.square_feet
    },
    onUpdateWantsSitter4Paws(options) {
      if ((options.attr == 'wantsSitter4Paws') ) {
        this.$store.commit('client/SET_ATTR', options)
      }
    },
    loadAddressFallback() {
      this.googleAutocompleteLoad = false;
    },
    ...mapActions({
      setClientAttr: 'client/setAttr',
      setProfileAttr: 'client/setProfileAttr',
      setPropertyAttr: 'property/setAttr'
    })
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'booking-bg';
    this.setClientAttr({ attr: 'referred_by', value: Window.signup_details.referral_code })
    this.setClientAttr({ attr: 'referral_id', value: Window.signup_details.referral_id })
    this.first_name = Window.signup_details.first_name
    this.last_name = Window.signup_details.last_name
    this.email = Window.signup_details.email
    this.$store.commit('STOP_INDICATOR')
    this.$store.dispatch('hideMenu', null, { root: true })
  }
}
</script>
