<template>
  <div id="content">
    <topheader></topheader>
    <div class="page-wrapper">
      <no-card-warning :no-card="clientHasNoCard"></no-card-warning>
      <texts-are-disabled-warning v-if="textsAreDisabled" :for-user="true"/>
      <h2 class="main-head text-center pb-5">Hello, {{full_name}}</h2>
      </br>
      <div class="box-wrapper" :class="lockedCss">
        <my-form :readonly="readonly" @update:edit-mode="onEditModeChange" :start-in-edit-mode="startInEditMode">
          <template v-slot:default="{ invalid, valid, formIsReadonly }">
            <div class="profile-image">
              <div class="profile-image-in">
                <placeholder-image />
              </div>
            </div>
            <locker-widget @update:locked="onLockedUpdate" v-bind="lockingParams" record-class="client" :record-id="clientId" ref="locker" />
            <div class="row">
              <div class="col-sm-3"><first-name-field v-model="first_name" :readonly="formIsReadonly" /></div>
              <div class="col-sm-3"><last-name-field v-model="last_name" :readonly="formIsReadonly" /></div>
              <div class="col-sm-6"><email-field v-model="email" :readonly="formIsReadonly" /></div>
              <phones :readonly="formIsReadonly" :phones="phones" @updated="savePhones" @update:phone-attr="updatePhoneAttr"></phones>
              <div class="col-sm-12 pt-0">
                <consent-checkbox v-model="text_consent" :readonly="formIsReadonly">
                  I agree to receive texts messages at the phone number provided
                </consent-checkbox>
              </div>
              <div class="col-sm-12 pt-0">
                <consent-checkbox v-model="text_offers_consent" :readonly="formIsReadonly">
                  I agree to receive recurring updates, news and offers at the phone number provided
                </consent-checkbox>
              </div>
              <div class="col-sm-12 pt-0">
                <p class="text-tiny">Consent is not a condition of purchase. Reply STOP to unsubscribe.
                  Reply HELP for help. Message frequency varies.
                  Msg & data rates may apply.
                  View our
                  <a href="https://www.wavehousecleaning.com/privacy-policy/" target="_blank">privacy policy</a> and
                  <a href="https://www.wavehousecleaning.com/terms-and-conditions/" target="_blank">terms of service</a>.
                </p>
              </div>
              <div class="mx-auto">
                <button type="button" @click="sendPasswordResetEmail" class="btn btn-default btn-orange btn-sm">Send password reset email</button>
              </div>
            </div>
            <template v-if="hasSecondaryContact || !formIsReadonly">
              <h6>Secondary Contact</h6>
              <div class="row">
                <div class="col-sm-6"><first-name-field v-model="secondary_contact" label="Name" :readonly="formIsReadonly" /></div>
                <div class="col-sm-6"><email-field v-model="secondary_email" :readonly="formIsReadonly" /></div>
                <div class="col-sm-6"><phone-field v-model="secondary_phone" :readonly="formIsReadonly" rules="min:7" :required="false" /></div>
              </div>
            </template>
            <div class="row">
              <div class="col-sm-12">
                <address-fields
                  :address="billingAddress"
                  :readonly="formIsReadonly"
                  @update="onAddressUpdate" />
              </div>
            </div>
          </template>
        </my-form>
      </div>
    </div>
  </div>
</template>
<script>
import FirstNameField from '@/shared/fields/first_name.vue'
import LastNameField from '@/shared/fields/last_name.vue'
import EmailField from '@/shared/fields/email.vue'
import AddressFields from '@/shared/address_fields.vue'
import Phones from '@/shared/phones.vue'
import PhoneField from '@/shared/fields/phone.vue'
import phones_mixin from '@/shared/phones_mixin'
import PlaceholderImage from '@/shared/avatar_placeholder.vue'
import locking_mixin from '@/shared/locking_mixin'
import no_card_warning_mixin from '@/shared/no_card_warning_mixin'
import TextsAreDisabledWarning from '@/shared/texts_are_disabled_warning.vue'
import ConsentCheckbox from '@/shared/fields/consent_checkbox.vue'

export default {
  components: { PlaceholderImage, Phones, FirstNameField, LastNameField, EmailField,
    PhoneField, TextsAreDisabledWarning, AddressFields, ConsentCheckbox },
  mixins: [ locking_mixin, no_card_warning_mixin, phones_mixin ],
  data() {
    return {
      fullAddress: null,
      fullAddressWithAddress2: null,
      first_name: null,
      last_name: null,
      email: null,
      profile_id: null,
      address: null,
      address2: null,
      city: null,
      zip_code: null,
      startInEditMode: false,
      secondary_contact: null,
      secondary_email: null,
      secondary_phone: null,
      text_consent: null,
      text_offers_consent: null,
    }
  },
  computed: {
    clientId() {
      return this.$store.getters['client/client_id']
    },
    full_name() {
      return this.$store.getters['client/full_name']
    },
    hasSecondaryContact() {
      return this.secondary_contact || this.secondary_phone || this.secondary_email
    },
    textsAreDisabled() {
      return this.$store.getters['client/textsAreDisabled']
    },
    billingAddress() {
      return this.$store.getters['client/billingAddress']
    }
  },
  created() {
    this.onClientLockChange(this.clientId, 'clients/views/profile', this.loadClientProfile)
  },
  mounted() {
    if (this.$attrs.startEdit != null) {
     this.startInEditMode = this.$attrs.startEdit
    }
    document.getElementsByTagName('body')[0].className = 'white-theme'
    this.$store.commit('STOP_INDICATOR')
    this.loadClientProfile()
  },
  methods: {
    loadClientProfile() {
      this.$store.dispatch('client/getClientData').then(() => {
        this.first_name = this.$store.getters['client/first_name']
        this.last_name = this.$store.getters['client/last_name']
        this.email = this.$store.getters['client/email']
        this.setPhones(this.$store.getters['client/phones'])
        this.locked = this.$store.getters['client/locked']
        this.readonly = this.$store.getters['client/readonly']
        this.lockedBy = this.$store.getters['client/lockedBy']
        this.secondary_contact = this.$store.getters['client/secondary_contact']
        this.secondary_email = this.$store.getters['client/secondary_email']
        this.secondary_phone = this.$store.getters['client/secondary_phone']
      })
      this.$store.dispatch('client/getProfile').then(() => {
        const profile = this.$store.getters['client/profile']
        this.address = this.billingAddress.address
        this.address2 = this.billingAddress.address2
        this.city = this.billingAddress.city
        this.zip_code = this.billingAddress.zip_code
        this.profile_id = profile.id
        this.text_consent = profile.text_consent
        this.text_offers_consent = profile.text_offers_consent
      })
    },
    onAddressUpdate({ field, value }) {
      this[field] = value
    },
    sendPasswordResetEmail() {
      this.$store.dispatch('client/sendPasswordResetEmail').then(() => {
        this.$flasher.success('Emailt sent, check your emails')
      })
    },
    submit() {
      return new Promise((resolve, reject) => {
        this.loading = true
        this.$store.commit('client/SET_ATTR', { attr: 'first_name', value: this.first_name })
        this.$store.commit('client/SET_ATTR', { attr: 'last_name', value: this.last_name })
        this.$store.commit('client/SET_ATTR', { attr: 'email', value: this.email })
        this.$store.commit('client/SET_ATTR', { attr: 'secondary_contact', value: this.secondary_contact })
        this.$store.commit('client/SET_ATTR', { attr: 'secondary_email', value: this.secondary_email })
        this.$store.commit('client/SET_ATTR', { attr: 'secondary_phone', value: this.secondary_phone })
        this.$store.commit('client/SET_PROFILE', {
          id: this.profile_id, address: this.address, address2: this.address2, zip_code: this.zip_code, city: this.city,
          text_consent: this.text_consent, text_offers_consent: this.text_offers_consent
        })
        this.$store.dispatch('client/update').then(() => {
          resolve()
        }, (errors) => {
          reject(errors)
        })
      })
    },
    savePhones() {
      this.$store.commit('client/SET_PHONES', this.phone_params.phones_attributes)
      this.$store.dispatch('client/update').then(() => {
        this.setPhones(this.$store.getters['client/phones'])
        this.$flasher.success('Phones updated successfully')
      })
    }
  }
}
</script>
