<template>
  <div class="selector__col">
    <span class="selector__label">{{serviceTitle}}</span>
    <div class="selector__counter counter">
      <button class="counter__btn counter__btn--increment" @click="$emit('remove', changeParam)">
        <i class="fa fa-minus"></i>
        <span class="hide">Add 1 {{areaTitle}} of Clean.</span>
      </button>
      <input class="selector__control form__control"
        type="text"
        :aria-label="serviceTitle"
        :value="qty()"
      />
      <button class="counter__btn counter__btn--decrement" @click="$emit('add', changeParam)">
        <i class="fa fa-plus"></i>
        <span class="hide">Remove 1 {{areaTitle}} of Clean.</span>
      </button>
    </div>
    <p class="selector__price-info"><span>{{price}}</span></p>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils'
export default {
  props: {
    area: {
      type: Object,
      required: true
    },
    areaKey: {
      type: String,
      required: true
    },
    service: {
      type: Object,
      required: true
    },
    pickedServices: {
      type: Object,
      required: true
    },
    areaService: {
      type: Object,
    //  required: true
    },
    areaServiceKey: {
      type: String,
//      required: true
    },
    prices: {
      type: Object,
  //    required: true
    }
  },
  data () {
    return {
    }
  },
  computed: {
    areaTitle() {
      return this.area.title
    },
    baseDomId() {
      var id = this.service.slug + '-' + this.areaKey
      if (this.areaServiceKey) {
        return id + '-' + this.areaServiceKey
      }
      return id
    },
    serviceTitle() {
      return this.service.title
    },
    serviceKey() {
      return this.service.slug
    },
    price() {
      if ( (this.areaServiceKey == 'clean') || (this.areaKey != 'sofa') ) {
        return formatCurrency(this.priceCents / 100)
      } else {
        const price = formatCurrency(this.perSeatPriceCents / 100)
        return `${price} each seat`
      }
    },
    priceKey() {
      return this.area.priceKey
    },
    priceData() {
      if (this.prices[this.priceKey]) {
        return this.prices[this.priceKey][this.areaServiceKey]
      }
    },
    priceDataType() {
      return typeof(this.priceData)
    },
    perSeatPriceCents() {
      if (this.priceDataType == 'object') {
        return this.priceData.per_seat
      }
    },
    priceCents() {
      if (this.priceDataType == 'number') {
        return this.priceData
      }
      if (this.priceDataType == 'object') {
        return this.priceData.base
      }
    },
    changeParam() {
      return { service: this.serviceKey, area: this.areaKey, areaService: this.areaServiceKey, priceKey: this.priceKey }
    }
  },
  methods: {
  },
}
</script>
