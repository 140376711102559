<template>
  <div class="row">
    <!-- for desktop -->
    <div class="property-container col-10 offset-1 col-md-8 offset-md-2 d-none d-sm-block" :class="propertySelected" @click.prevent="$emit('picked', property.id)" @mouseover="mouseOver(propertyIndex)">
      <div>
        <div class="float-left mr-3">
          <img src="@images/home-icon.png" class="icon-properties" alt="Where we clean" srcset="@images/home-icon@2x.png 2x" />
        </div>
        <div>
          <p>{{property.address_line1}} ({{property.rooms}})<br>{{property.city}}</p>
        </div>
      </div>
    </div>

    <!-- For mobile -->
     <div v-hammer:tap="(event)=> onTap(event, property.id)" v-show="propertySelected" class="property-container col-10 offset-1 col-md-8 offset-md-2 d-block d-sm-none" :class="propertySelected">
      <div @click="mouseOver(propertyIndex)" @mouseover="mouseOver(propertyIndex)" class="d-flex">
        <div class="mr-3">
          <img src="@images/home-icon.png" class="icon-properties" alt="Where we clean" srcset="@images/home-icon@2x.png 2x" />
        </div>
        <div class="align-self-center">
          <p class="my-0">{{property.address_line1}} ({{property.rooms}})<br>{{property.city}}<br>
          </p>
        </div>
      </div>
      <p class="text-center my-0"><small><i class="fal fa-angle-left">&nbsp;</i>Tap here for booking <i class="fal fa-angle-right"></i></small></p>
    </div>

  </div>
</template>

<script>
import Vue from "vue";
import { VueHammer } from 'vue2-hammer'

Vue.use(VueHammer)

export default {


  props: {
    property: {
      type: Object,
      required: true
    },
    carouselIndex : {
      type: Number,
      default: 0
    },
    propertyIndex : {
      type: Number,
      default: 0
    }
  },
  computed: {
    transformString() {
      const { x, y } = this.interactPosition;
      return `translate3D(${x}px, ${y}px, 0)`;
    },
    propertySelected(){
      if(this.carouselIndex===this.propertyIndex){
        return 'property-selected fade-in'
      } else {
        return null
      }
    }
  },
  methods: {
    mouseOver(idx) {
      this.$emit('propertyIdxHover', idx)
    },
    onTap (event, propertyIndex) {
      this.$emit('picked', propertyIndex)
    }
  }
}
</script>
