<template>
  <div>
    <b-modal id="pets-offer-modal" centered content-class="modal-white-theme" @ok="ok()">
      <div class="text-center">
        <a href="https://www.sitter4paws.com/" target="_blank">
          <img class="img-fluid rounded" src="@images/sitter4paws-logo.png" />
        </a>
      </div>
      <p class="mt-4 mx-2">
        WHC does not clean messes such as animal feces, or cannot handle or feed animals during the visit or clean litter boxes, Sitter4paws can not only do that, but we offer dog walks and pet sitting.
      </p>
      <p class="my-4 mx-2">
        Can we contact you with this information? Here's $20 for your first visit & free consultation.
      </p>
      <template centered #modal-footer="{ ok }">
        <b-button size="sm" variant="success" @click="ok()">
          Yes, I'm interested
        </b-button>
        <b-button size="sm" variant="outline-secondary" @click="forget()">
          No, thank you
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  data: () => ({
    isInterested: false
  }),
  methods: {
    ok() {
      this.isInterested = true
      this.$emit('update',{attr: 'wantsSitter4Paws', value: this.isInterested})
    },
    forget() {
      this.isInterested = false
      this.$emit('update',{attr: 'wantsSitter4Paws', value: this.isInterested})
      this.$bvModal.hide('pets-offer-modal')
    }
  }
}
</script>
