<template>
  <div>
  <div id="content">
    <topheader></topheader>
    <mobile-summary-widget
        :info="summaryInfo"
        :promo-code="promoCode"
        @save:promo_code="$emit('save:promo_code')"
        @remove:promo_code="$emit('remove:promo_code')"
        @update:promo_code="$emit('update:promo_code', $event)"
    >
      <template v-slot:additional-section>
        <additional-info
          v-if="askAdditionalQuestions"
          :show-title="false"
          :questions="additionalQuestions"
          :values="additionalValues"
          @update="onUpdateInfo"
          form-class="form-check form-check-inline"
        />
      </template>
    </mobile-summary-widget>
    <div class="container-fluid px-5">
      <div class="row d-flex justify-content-center">
        <div class="content-wrapper booking-main-content mx-3 my-5">
          <step-indicator />
          <div class="form-wrapper">
            <div class="prev-button" @click="toPrevStep">
              <i class="fas fa-angle-left fa-3x"></i>
            </div>
            <booking-form ref="booking">
              <template v-slot="{ errors }">
              <input type="hidden" id="start">
              <input type="hidden" id="status">
              <h2  class="form-head mb-4 pt-sm-4">{{title}}</h2>
              <div class="row mb-2"><div class="col-sm text-center">{{ fullAddress }}</div></div>
              <has-pet-question v-model="hasPet" @update:wants-sitter-paws="onUpdateWantsSitter4Paws" :wants-sitter4-paws="wantsSitter4Paws"></has-pet-question>
              <div class="btn-set js-blink" id="scrollElement">
                <booking-start-picker-buttons :start-picked="startPicked" :start="start" @toggle-modal="onToggleModal" @toggle-quote-only="onToggleQuoteOnly" :quote-only="quoteOnly" :flexible-description="flexibleDescription" />
              </div>
              <div class="row">
                <div class="col-12">
                  <h2 class="form-head">Notes</h2>
                  <advanced-notes :errors="errors" />
                </div>
              </div>
            </template>
              <template v-slot:buttons="{ invalid, validated }">
                <b-button :disabled="(invalid && validated) || loading" type="submit" variant="primary" class="main-btn mt-5">Book Now</b-button>
              </template>
            </booking-form>
            <booking-start-picker-modal :size="teamRequired" :zip="zip_code" start-field="#start" status-field="#status" :datetime="start" :min-date="minDateAsStr" :show="showModal" @start-picked="onStartPicked" @show-flexible="onShowFlexible" @update:show-modal="showModal=$event" :location-id="location_id" :quote-only="quoteOnly" />
          </div>
        </div>
        <div class="content-wrapper booking-secondary-content d-none d-md-block">
            <desktop-summary-widget
                :info="summaryInfo"
                :promo-code="promoCode"
                @save:promo_code="$emit('save:promo_code')"
                @update:promo_code="$emit('update:promo_code', $event)"
                @remove:promo_code="$emit('remove:promo_code')"
            />
          <additional-info
            v-if="askAdditionalQuestions"
            :show-title="false"
            :questions="additionalQuestions"
            :values="additionalValues"
            @update="onUpdateInfo"
            class="breakdown-summary-wrapper mt-2"
          />
        </div>
      </div>
    </div>
  </div>
  <double-booking-modal :show="showDoubleBookingModal" :content="doubleBookingMessage" @confirm-double-booking="confirmDoubleBooking" />
  <flexible-options-modal @selected="flexibleSelected" :show="showFlexible" @close="showFlexible = false" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AdvancedNotes from '../booking/advanced_notes.vue'
import BookingStartPickerButtons from '../booking/start_picker_buttons.vue'
import BookingStartPickerModal from '@/start_picker/for_client_booking_in_modal.vue'
import DoubleBookingModal from '../booking/double_booking_modal.vue'
import FlexibleOptionsModal from '@/modals/flexible_options_modal.vue'
import HasPetQuestion from '../has_pet_question.vue'
import router from '../router'
import { dateToAppFormat } from '@/utils'

import AdditionalInfo from '@/clients/booking/shared/additional_info.vue'

import $ from 'jquery'
import Vue from 'vue'

export default {
  components: { AdvancedNotes, AdditionalInfo, BookingStartPickerButtons, BookingStartPickerModal, DoubleBookingModal, FlexibleOptionsModal, HasPetQuestion },
  computed: {
    askAdditionalQuestions() {
      if (this.askParking) {
        return true
      }
      return true
    },
    hasPet: {
      get() {
        return this.$store.getters['property/hasPet']
      },
      set(v) {
        this.setPropertyAttr({ attr: 'hasPet', value: v })
      }
    },
    start: {
      get() {
        return this.$store.getters['reservation/start']
      },
      set(v) {
        this.setReservationAttr({ attr: 'start', value: v })
      }
    },
    quoteOnly() {
      return this.status == 'quote_only'
    },
    status: {
      get() {
        return this.$store.getters['reservation/status']
      },
      set(v) {
        this.setReservationAttr({ attr: 'status', value: v })
      }
    },
    promo_code: {
      get() {
        return this.$store.getters['reservation/promo_code']
      },
      set(v) {
        this.setReservationAttr({ attr: 'promo_code', value: v })
      }
    },
    minDateAsStr() {
      return dateToAppFormat(this.minDate)
    },
    title() {
      if (this.isLocationIncomplete) {
        return 'About your reservation'
      } else {
        return 'Confirm Details'
      }
    },
    flexibleDescription() {
      if (this.isFlexibleBooking) {
        return this.flexible.description
      }
    },
    additionalValues() {
      let values = { }
      if (this.askParking) {
        values.hasFreeParking = this.$store.getters['property/hasFreeParking']
      }
      return values
    },
    additionalQuestions() {
      let questions = {}
      if (this.askParking) {
        questions.hasFreeParking = 'Available free parking on site?'
      }
      return questions
    },
    ...mapGetters(['minDate', 'summaryInfo', 'loading', 'doubleBookingMessage', 'showDoubleBookingModal']),
    ...mapGetters({ promoCode: 'reservation/promo_code' }),
    ...mapGetters({ flexible: 'reservation/flexible', isFlexibleBooking: 'reservation/isFlexibleBooking' }),
    ...mapGetters('client', ['wantsSitter4Paws']),
    ...mapGetters('property', ['location_id', 'teamRequired', 'isLocationIncomplete', 'askParking', 'zip_code', 'fullAddress', 'isNew' ]),
  },
  data() {
    return {
      showModal: false,
      showFlexible: false,
      startPicked: null
    }
  },
  methods: {
    onAddressChange(e) {
    },
    confirmDoubleBooking() {
      this.$store.commit('SET_IGNORE_DOUBLE_BOOKING')
      this.$refs.booking.onSubmit()
    },
    submit() {
      return new Promise((resolve, reject) => {
        if ((this.status != 'quote_only') && !this.isFlexibleBooking) {
          if ( (this.start == '') || (this.status == null) || (this.status == '') ) {
            // Scroll to buttom
            const element = document.getElementById("scrollElement");
            const intersectionObserver = new IntersectionObserver((entries) => {
              let [entry] = entries;
              if (entry.isIntersecting) {
                setTimeout(() => $('.js-blink').fadeIn(100).fadeOut(100).fadeIn(100).fadeOut(100).fadeIn(100), 100)
              }
            });

            intersectionObserver.observe(scrollElement);
            element.scrollIntoView({block: "end", behavior: "smooth"});

            reject({})
            return
          }
        }
        this.$store.dispatch('book').then(() => {
          resolve()
        }, (errors) => {
          reject(errors)
        }).catch((errors) => {
          reject(errors)
        })
      })
    },
    clearFlexible() {
      this.$store.commit('reservation/SET_FLEXIBLE_OPTIONS', {})
    },
    onShowFlexible() {
      this.startPicked = ''
      this.showFlexible = true
    },
    flexibleSelected(options) {
      this.status = 'flexible'
      this.$store.commit('reservation/SET_FLEXIBLE_OPTIONS', options)
      this.showFlexible = false
      this.showModal = false
    },
    onUpdateInfo(options) {
      if ( (options.attr == 'hasFreeParking')) {
        this.$store.commit('property/SET_ATTR', options)
      } else {
        this.$store.commit('reservation/UPDATE_INFO', options)
      }
    },
    onStartPicked(formattedStart, start, status) {
      this.startPicked = formattedStart
      this.start = start
      this.status = status
      this.showModal = false
      this.clearFlexible()
    },
    onToggleModal() {
      this.showModal = !this.showModal
      if (this.showModal && this.isFlexibleBooking) {
        this.clearFlexible()
      }
    },
    onToggleQuoteOnly(newQuoteOnly) {
      if (newQuoteOnly) {
        this.startPicked = ''
        this.start = ''
        this.status = 'quote_only'
      } else {
        this.status = ''
      }
    },
    toPrevStep() {
      this.$store.dispatch('toExtras', null, { root: true })
    },
    onUpdateWantsSitter4Paws(options) {
      if ((options.attr == 'wantsSitter4Paws') ) {
        this.$store.commit('client/SET_ATTR', options)
      }
    },
    ...mapActions({
      setReservationAttr: 'reservation/setAttr',
      setClientAttr: 'client/setAttr',
      setPropertyAttr: 'property/setAttr'
    }),
  },
  mounted() {
    document.getElementsByTagName('body')[0].className = 'booking-bg'
    this.$store.commit('STOP_INDICATOR')
  }
}
</script>
